import { AnyUserAction } from './action';
import { USER_ACTION_TYPES } from './constants';
import { IUserState } from './types';

export const initialState: IUserState = {
  selectedUserId: '',
  userListAutoComplete: [],
  userList: [],
  userListTotalCount: 0,
  userStatistic: {
    activeCount: 0,
    deletedCount: 0,
    passiveCount: 0,
    totalCount: 0,
  },
  userDetail: {},
  eventTypes: [
    {
      description: 'Activation',
      eventType: 1,
    },
    {
      description: 'Login',
      eventType: 2,
    },
    {
      description: 'Fail Login',
      eventType: 3,
    },
    {
      description: 'Change Password',
      eventType: 4,
    },
  ],
};

export const userReducer = (
  state: IUserState = initialState,
  action: AnyUserAction,
): IUserState => {
  switch (action.type) {
    case USER_ACTION_TYPES.SET_USER_AUTOCOMPLETE_LIST:
      return {
        ...state,
        userListAutoComplete: action.payload.data,
      };
    case USER_ACTION_TYPES.SET_USER_STATISTIC:
      return {
        ...state,
        userStatistic: action.payload.data,
      };
    case USER_ACTION_TYPES.GET_USER_SUCCESS:
      return {
        ...state,
        userDetail: { ...state.userDetail, ...action.payload },
      };
    case USER_ACTION_TYPES.CLEAR_USER_DETAIL:
      return {
        ...state,
        userDetail: {},
      };
    case USER_ACTION_TYPES.SET_USER_LIST:
      return {
        ...state,
        userList: action.payload.data,
        userListTotalCount: action.payload.filteredCount,
      };
    case USER_ACTION_TYPES.SET_SELECTED_USER_ID:
      return {
        ...state,
        selectedUserId: action.payload,
      };
    case USER_ACTION_TYPES.SET_USER_ACTIVITIES:
      return {
        ...state,
        userDetail: { ...state.userDetail, userActivities: action.payload },
      };
    default:
      return {
        ...state,
      };
  }
};
