import { action } from 'typesafe-actions';
import { APP_ACTION_TYPES } from './constants';
import { INotificationModel } from './types';

export const setNotification = (payload: INotificationModel) =>
  action(APP_ACTION_TYPES.SET_NOTIFICATION, payload);
export const setCallNameForLoader = (payload: string) =>
  action(APP_ACTION_TYPES.SET_CALL_NAME_FOR_LOADER, payload);
export const setHelmetIdentifier = (payload: string) =>
  action(APP_ACTION_TYPES.SET_HELMET_IDENTIFIER, payload);

export type AnyAppAction =
  | ReturnType<typeof setNotification>
  | ReturnType<typeof setHelmetIdentifier>
  | ReturnType<typeof setCallNameForLoader>;
