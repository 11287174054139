import React from 'react';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { History } from 'history';
import { Route, Router, Switch } from 'react-router-dom';
import { Store, AnyAction } from 'redux';
import { IApplicationState } from 'src/store/ducks/types';
import * as routes from 'src/routes/index';
import MainLayout from 'src/components/layouts/MainLayout';
import RouteRenderer from 'src/components/RouteRenderer';
import RouteBase from 'src/routes/RouteBase';
import NotFoundPage from 'src/pages/NotFound';
import { ModalProvider } from 'react-modal-hook';
import moment from 'moment';
import 'moment/locale/tr';

type AppPropTypes = {
  store: Store<IApplicationState, AnyAction>;
  history: History;
};
const App: React.FC<AppPropTypes> = ({ store, history }: AppPropTypes) => {
  const renderRoutes: () => JSX.Element = () => {
    const routeArr: RouteBase[] = Object.values(routes);
    moment.locale('tr');
    return (
      <>
        {routeArr.map((route: RouteBase) => (
          <Route
            key={route.fullPath}
            path={route.fullPath}
            exact={true}
            render={props => (
              <RouteRenderer route={route} exact={true} {...props} />
            )}
          />
        ))}
      </>
    );
  };

  return (
    <Provider store={store}>
      <HelmetProvider>
        <Router history={history}>
          <ModalProvider>
            <MainLayout>
              <Route
                render={({ location }) => (
                  <Switch location={location}>
                    {renderRoutes()}
                    <Route component={NotFoundPage} />
                  </Switch>
                )}
              />
            </MainLayout>
          </ModalProvider>
        </Router>
      </HelmetProvider>
    </Provider>
  );
};

export default App;
