import generateDynamicTypes from 'src/common/helpers/generateDynamicTypes';

export const USER_ACTION_TYPES = {
  ...generateDynamicTypes(
    [
      'GET_USER_LIST',
      'SET_USER_LIST',
      'GET_USER_STATISTIC',
      'SET_USER_STATISTIC',
      'GET_USER_AUTOCOMPLETE_LIST',
      'SET_USER_AUTOCOMPLETE_LIST',
      'SET_USER',
      'GET_USER',
      'GET_USER_SUCCESS',
      'UPDATE_USER',
      'DELETE_USER',
      'SET_SELECTED_USER_ID',
      'GET_USER_ACTIVITIES',
      'SET_USER_ACTIVITIES',
      'SET_USER_STATUS',
      'SEND_USER_EMAIL_CONFIRMATION',
      'SEND_USER_SMS_CONFIRMATION',
      'CLEAR_USER_DETAIL',
    ],
    '@@user',
  ),
};
