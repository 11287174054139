import React, { useEffect, useRef, useState } from 'react';
import tw from 'twin.macro';
import styled, { css } from 'styled-components';
import { Icon } from 'roadrunner.uiframework';
import QRCode from 'react-qr-code';
import useOnClickOutside from 'src/common/hooks/onClickOutside';
import { useSelector } from 'react-redux';
import { IApplicationState } from 'src/store/ducks/types';

interface ILoginQrProps {
  text: string;
  title: string;
  onClose: Function;
  generateQr: Function;
}

const LoginContainer = styled.div(() => [
  tw`bg-white-500 border-grey-200 border-2 box-shadow[0px 2px 10px rgba(0, 0, 0, 0.03)] border-radius[4px] padding[14px 14px 0px 14px] flex flex-col items-center relative w-full`,
]);
const CloseIcon = styled.div(() => [
  tw`absolute right-4 text-grey-400 text-base cursor-pointer`,
]);
const LoginHeader = styled.div(() => [tw`flex flex-col self-start gap-y-1`]);
const Title = styled.span(() => [tw`text-lg`]);
const Text = styled.span(() => [
  tw`text-sm leading-5 font-medium text-grey-500`,
]);
const QrSession = styled.div(() => [
  tw`w-full h-56 flex items-center justify-center margin[44px 0]`,
]);

const LoginQr: React.FC<ILoginQrProps> = (props: ILoginQrProps) => {
  const ref: any = useRef();
  const accountState = useSelector((state: IApplicationState) => state.account);

  useOnClickOutside(ref, () => props.onClose());
  useEffect(() => {
    props.generateQr();
    const interval = setInterval(() => {
      props.generateQr();
    }, 60000);
    return () => clearInterval(interval);
  }, []);
  return (
    <LoginContainer ref={ref}>
      <CloseIcon
        onClick={() => {
          props.onClose();
        }}
      >
        {<Icon name='close' />}
      </CloseIcon>
      <LoginHeader>
        <Title>{props.title}</Title>
        <Text>{props.text}</Text>
      </LoginHeader>
      <QrSession>
        {accountState.qrLoginString && (
          <QRCode value={accountState.qrLoginString} />
        )}
      </QrSession>
    </LoginContainer>
  );
};

export default LoginQr;
