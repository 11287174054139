import store from 'src/store';
import { setCallNameForLoader } from 'src/store/ducks/app/action';

const arrangeCallNamesForLoader = (str: string): [] => {
  let statedUrlArray = store.getState().app.activeCalls;
  let index = statedUrlArray.findIndex(item => item === str);
  if (index > -1) {
    statedUrlArray.splice(index, 1);
  } else {
    statedUrlArray = [...statedUrlArray, str];
  }
  store.dispatch(setCallNameForLoader(statedUrlArray));
  return [];
};

export default arrangeCallNamesForLoader;
