import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { useForm, SubmitHandler } from 'react-hook-form';
import FiltersComponent from 'src/components/Filter';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormTextInput from 'src/components/TextBox';
import FormSelectInput from 'src/components/SelectBox';
import { fetchGetRoleListRequest } from 'src/store/ducks/role/action';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from 'src/store/ducks/types';
import history from 'src/common/history';
import setFilterToParams from 'src/common/helpers/setFilterToParams';
import parseParams from 'src/common/helpers/paramParser';
type IUserFilter = {
  name?: string;
  mail?: string;
  phone?: string;
  roleList?: string[];
  onClose: Function;
  onSearch: Function;
};

const statuType = [
  {
    value: 1,
    label: 'Aktif',
  },
  {
    value: 0,
    label: 'Pasif',
  },
];

const schema = yup.object({});

const UserFilter: React.FC<IUserFilter> = (props: IUserFilter) => {
  const [roleList, setroleList] = useState([]);
  const roleState = useSelector((state: IApplicationState) => state.role);
  const queryParams: any = parseParams(
    decodeURIComponent(window.location.search),
  );
  const dispatch: Dispatch = useDispatch();
  const { handleSubmit, setValue, reset, control } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(fetchGetRoleListRequest({}));
  }, []);

  useEffect(() => {
    let tempArray: any = [];
    let filteredRoles: any;
    roleState?.roleList?.map(item => {
      tempArray.push({
        value: item.roleId,
        label: item.name,
      });
    });
    setroleList(tempArray);
    if (queryParams) {
      if (queryParams.roleIds && roleState.roleList.length) {
        queryParams.roleIds =
          typeof queryParams.roleIds === 'string'
            ? [queryParams.roleIds]
            : queryParams.roleIds;
        filteredRoles = queryParams.roleIds?.map((roleId: any) => {
          let role = roleState.roleList.find(
            item => item.roleId === roleId.toString(),
          );
          return { value: role.roleId, label: role.name };
        });
      }
      reset({
        ...queryParams,
        roleIds: filteredRoles,
        status: {
          value: queryParams.status,
          label:
            queryParams.status === '1'
              ? 'Aktif'
              : queryParams.status === '0'
              ? 'Pasif'
              : 'Seçiniz',
        },
      });
    } else {
      reset({
        status: { value: 1, label: 'Aktif' },
      });
    }
  }, [roleState.roleList]);

  const onSubmit: SubmitHandler<any> = data => {
    let searchParams = parseParams(decodeURIComponent(window.location.search));
    setFilterToParams({ ...searchParams, ...data });
    props.onSearch && props.onSearch({ ...data, ...searchParams });
    props.onClose && props.onClose();
  };

  const onClearFilter: Function = () => {
    history.push('/kullanicilar');
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className='h-full'>
        <FiltersComponent onClose={props.onClose} onClear={onClearFilter}>
          <FormTextInput
            control={control}
            name='term'
            placeholder='Adı'
            size='large'
            setValue={setValue}
          />
          <ModalSubTittleSpan className='text-base'>
            Detaylı Arama
          </ModalSubTittleSpan>
          <FormTextInput
            control={control}
            name='name'
            placeholder='Adı'
            size='small'
            setValue={setValue}
          />
          <FormTextInput
            control={control}
            name='email'
            placeholder='E-posta'
            size='small'
            setValue={setValue}
          />
          <FormTextInput
            control={control}
            name='phone'
            placeholder='Telefon'
            size='small'
            setValue={setValue}
          />
          <FormSelectInput
            placeholder='Rol Seciniz'
            size='small'
            type='multiple'
            name='roleIds'
            options={roleList}
            control={control}
          />
          <ModalSubTittleSpan className='text-base'>Statü</ModalSubTittleSpan>
          <FormSelectInput
            placeholder='Statu seciniz'
            size='small'
            name='status'
            control={control}
            options={[
              {
                value: '',
                label: 'Seçiniz',
              },
              ...statuType,
            ]}
          />
        </FiltersComponent>
      </form>
    </>
  );
};

export default UserFilter;

const ModalSubTittleSpan = styled.span(() => [
  tw`flex items-start justify-start text-base mb-1`,
]);
