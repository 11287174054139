import createApiEndpoint from 'src/httpClient/endpoints';
import { REQUEST_METHODS } from 'src/httpClient/constants';
import {
  GET_PERMISSION_LIST,
  GET_ROLE_LIST,
  GET_ROLE_TYPE_LIST,
} from 'src/httpClient/endpoints/roles/constants';
import {
  ICreateRoleReqModel,
  IDeleteRoleReqModel,
  IGetRoleDetailReqModel,
  IGetRolePermissionReqModel,
  IGetRoleReqModel,
} from 'src/store/ducks/role/types';

export const getRoleListFetch = (params: IGetRoleReqModel) => {
  return createApiEndpoint(GET_ROLE_LIST, REQUEST_METHODS.GET, {}, params);
};
export const getPermissionListFetch = () => {
  return createApiEndpoint(GET_PERMISSION_LIST, REQUEST_METHODS.GET);
};
export const getRoleTypeListFetch = () => {
  return createApiEndpoint(GET_ROLE_TYPE_LIST, REQUEST_METHODS.GET);
};
export const createRoleFetch = (payload: ICreateRoleReqModel) => {
  return createApiEndpoint(GET_ROLE_LIST, REQUEST_METHODS.POST, payload);
};
export const deleteRoleFetch = (payload: IDeleteRoleReqModel) => {
  return createApiEndpoint(
    GET_ROLE_LIST + `/${payload.id}/false`,
    REQUEST_METHODS.DELETE,
  );
};
export const updateRoleFetch = (
  payload: ICreateRoleReqModel,
  params: IGetRoleDetailReqModel,
) => {
  return createApiEndpoint(
    `${GET_ROLE_LIST}/${params.id}`,
    REQUEST_METHODS.PUT,
    payload,
  );
};
export const getRoleFetch = (payload: IGetRoleDetailReqModel) => {
  return createApiEndpoint(
    `${GET_ROLE_LIST}/${payload.id}`,
    REQUEST_METHODS.GET,
  );
};
export const getRolePermissionFetch = (payload: IGetRolePermissionReqModel) => {
  return createApiEndpoint(
    `${GET_ROLE_LIST}/${payload.id}/permissions`,
    REQUEST_METHODS.GET,
  );
};
