import generateDynamicTypes from 'src/common/helpers/generateDynamicTypes';

export const ACCOUNT_ACTION_TYPES = {
  ...generateDynamicTypes(
    [
      'GET_USER_INFORMATION',
      'SET_USER_INFORMATION',
      'GET_QR_LOGIN_STRING',
      'SET_QR_LOGIN_STRING',
      'GET_MENU',
      'SET_MENU',
    ],
    '@@account',
  ),
};
