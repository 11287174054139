import { AxiosError, AxiosResponse } from 'axios';

import {
  IGetRoleReqModel,
  IRoleState,
  IGetRoleDetailReqModel,
  ICreateRoleReqModel,
  IGetRolePermissionReqModel,
  IDeleteRoleReqModel,
} from 'src/store/ducks/role/types';
import {
  createRoleFetch,
  deleteRoleFetch,
  getPermissionListFetch,
  getRoleFetch,
  getRoleListFetch,
  getRolePermissionFetch,
  getRoleTypeListFetch,
  updateRoleFetch,
} from '../endpoints/roles';

export const getRoleListFetchResolver: (
  params: IGetRoleReqModel,
) => Promise<AxiosResponse<IRoleState>> = (params: IGetRoleReqModel) => {
  return getRoleListFetch(params)
    .then((res: AxiosResponse<IRoleState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const createRoleFetchResolver: (
  params: ICreateRoleReqModel,
) => Promise<AxiosResponse<IRoleState>> = (params: ICreateRoleReqModel) => {
  return createRoleFetch(params)
    .then((res: AxiosResponse<IRoleState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const deleteRoleFetchResolver: (
  params: IDeleteRoleReqModel,
) => Promise<AxiosResponse<IRoleState>> = (params: IDeleteRoleReqModel) => {
  return deleteRoleFetch(params)
    .then((res: AxiosResponse<IRoleState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const updateRoleFetchResolver: (
  payload: ICreateRoleReqModel,
  params: any,
) => Promise<AxiosResponse<IRoleState>> = (
  payload: ICreateRoleReqModel,
  params: any,
) => {
  return updateRoleFetch(payload, params)
    .then((res: AxiosResponse<IRoleState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const getRoleFetchResolver: (
  params: IGetRoleDetailReqModel,
) => Promise<AxiosResponse<IRoleState>> = (params: IGetRoleDetailReqModel) => {
  return getRoleFetch(params)
    .then((res: AxiosResponse<IRoleState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const getRolePermissionFetchResolver: (
  params: IGetRolePermissionReqModel,
) => Promise<AxiosResponse<IRoleState>> = (
  params: IGetRolePermissionReqModel,
) => {
  return getRolePermissionFetch(params)
    .then((res: AxiosResponse<IRoleState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const getPermissionListFetchResolver: () => Promise<
  AxiosResponse<IRoleState>
> = () => {
  return getPermissionListFetch()
    .then((res: AxiosResponse<IRoleState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const getRoleTypeListFetchResolver: () => Promise<
  AxiosResponse<IRoleState>
> = () => {
  return getRoleTypeListFetch()
    .then((res: AxiosResponse<IRoleState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
