import React from 'react';
import { Controller } from 'react-hook-form';
import { TextArea } from 'roadrunner.uiframework';

export default function FormTextArea(props) {
  const { placeholder, name, control, rules, rows, ...rest } = props;
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <TextArea
            placeholder={placeholder}
            rows={rows}
            {...field}
            {...rest}
          />
        )}
      />
    </>
  );
}
