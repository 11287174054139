import React, { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import FiltersComponent from 'src/components/Filter';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormTextInput from 'src/components/TextBox';
import history from 'src/common/history';
import setFilterToParams from 'src/common/helpers/setFilterToParams';
import parseParams from 'src/common/helpers/paramParser';
type IUserFilter = {
  name?: string;
  mail?: string;
  phone?: string;
  roleList?: string[];
  onClose: Function;
  onSearch: Function;
};

const schema = yup.object({});

const ServiceFilter: React.FC<IUserFilter> = (props: IUserFilter) => {
  const queryParams: any = parseParams(
    decodeURIComponent(window.location.search),
  );
  const { handleSubmit, reset, control } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (queryParams) {
      reset({
        ...queryParams,
      });
    }
  }, []);

  const onSubmit: SubmitHandler<any> = data => {
    let searchParams = parseParams(decodeURIComponent(window.location.search));
    setFilterToParams({ ...searchParams, ...data });
    props.onSearch && props.onSearch({ ...data, ...searchParams });
    props.onClose && props.onClose();
  };

  const onClearFilter: Function = () => {
    history.push('/hizmetler');
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className='h-full'>
        <FiltersComponent onClose={props.onClose} onClear={onClearFilter}>
          <FormTextInput
            control={control}
            name='ServiceTypeName'
            placeholder='Hizmet Adı'
            size='small'
          />
          <FormTextInput
            control={control}
            name='ShortName'
            placeholder='Hizmet Kısa Adı'
            size='small'
          />
          <FormTextInput
            control={control}
            name='ServiceCode'
            placeholder='Hizmet Kodu'
            size='small'
          />
        </FiltersComponent>
      </form>
    </>
  );
};

export default ServiceFilter;
