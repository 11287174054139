import { AnyVendorAction } from './action';
import { VENDOR_ACTION_TYPES } from './constants';
import { IVendorState } from './types';

export const initialState: IVendorState = {
  vendorList: [],
  totalCount: 0,
  filteredCount: 0,
};

export const vendorReducer = (
  state: IVendorState = initialState,
  action: AnyVendorAction,
): IVendorState => {
  switch (action.type) {
    case VENDOR_ACTION_TYPES.SET_VENDOR_LIST:
      return {
        ...state,
        vendorList: action.payload.data,
        totalCount: action.payload.totalCount,
        filteredCount: action.payload.filteredCount,
      };
    default:
      return {
        ...state,
      };
  }
};
