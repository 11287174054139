import { AnyAccountAction } from './action';
import { ACCOUNT_ACTION_TYPES } from './constants';
import { IAccountState } from './types';

export const initialState: IAccountState = {
  userName: '',
  userId: '',
  email: '',
  phoneNumber: '',
  qrLoginString: '',
  menuTree: [
    {
      label: 'Dashboard',
      action: '',
    },
    {
      label: 'Paket Yönetimi',
      action: '',
      child: [
        {
          label: 'Paket Listesi',
        },
        {
          label: 'Adres Yönetimi',
        },
      ],
    },
    {
      label: 'Şube Yönetimi',
      action: '',
    },
    {
      label: 'Vendor',
      action: '',
    },
    {
      label: 'Ayarlar',
      action: '',
      child: [
        {
          label: 'Kullanıcı Yönetimi',
        },
        {
          label: 'Rol Yönetimi',
          action: '/Rol',
        },
        {
          label: 'Hizmet Yönetimi',
        },
        {
          label: 'SMS Yönetimi',
        },
        {
          label: 'Zone Yönetimi',
        },
        {
          label: 'Arac Yönetimi',
        },
      ],
    },
  ],
};

export const accountReducer = (
  state: IAccountState = initialState,
  action: AnyAccountAction,
): IAccountState => {
  switch (action.type) {
    case ACCOUNT_ACTION_TYPES.SET_USER_INFORMATION:
      return {
        ...state,
        userName: action.payload.userName,
        userId: action.payload.userId,
        email: action.payload.email,
        phoneNumber: action.payload.phoneNumber,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        isImpersonating: action.payload.isImpersonating,
      };
    case ACCOUNT_ACTION_TYPES.SET_QR_LOGIN_STRING:
      return {
        ...state,
        qrLoginString: action.payload.code,
      };
    default:
      return {
        ...state,
      };
  }
};
