import speakingurl from 'speakingurl';
import { capitalize } from 'src/common/helpers';
import { RouteComponentProps } from 'react-router-dom';
import { stringify } from 'query-string';

interface IRouteBase {
  name: string;
  title: string;
  fullTitle: string;
  path: string;
  fullPath: string;
  isPrivate: boolean;
  component: React.ComponentType<RouteComponentProps>;
  toUrl: (pathParams?: KV, queryParams?: KV) => string;
}

type RouteBaseProps = {
  name: string;
  component: React.ComponentType<RouteComponentProps>;
  path?: string;
  title?: string;
  isPrivate?: boolean;
  parent?: RouteBase;
  children?: { [key: string]: RouteBase };
};

type KV = { [key: string]: any };

class RouteBase implements IRouteBase {
  public name: string;
  public component: React.ComponentType<RouteComponentProps>;
  public title: string;
  public path: string;
  public isPrivate: boolean;
  public parent?: RouteBase;
  public fullPath: string;
  public fullTitle: string;
  public children: { [key: string]: RouteBase } | null;

  constructor(props: RouteBaseProps) {
    this.name = props.name;
    this.component = props.component;
    this.title = capitalize(props.title || props.name);
    this.parent = props.parent;
    this.isPrivate = !!props.isPrivate;

    const isRootPath: boolean = props.path?.length === 1 && props.path === '/';

    this.path = `${isRootPath ? '' : '/'}${speakingurl(
      props.path || props.name,
      {
        uric: true, // allow special charachters: ";", "?", ":", "@", "&", "=", "+", "$", ",", "/"
      },
    )}`;
    this.fullPath = '';
    this.fullTitle = '';
    this.children = null;

    this.toFullPath();
    this.toFullTitle();
  }

  private toFullPath() {
    let returnUrl = '';
    if (this.parent) {
      returnUrl = this.parent.toFullPath();
    }

    returnUrl += this.path;

    this.fullPath = returnUrl;
    return returnUrl;
  }

  private toFullTitle() {
    let returnTitle = '';
    if (this.parent) {
      returnTitle = this.parent.toFullTitle();
    }

    returnTitle += this.parent ? `/${this.title}` : this.title;

    this.fullTitle = returnTitle;
    return returnTitle;
  }

  /**
   ** Generates urls with urlSearchParams & query-strings
   * Example:
   * ===============
   * For a route object with path products/:id/detail
   * routeObj.toUrl({ id: 1 })
   *     returns products/1/detail
   * routeObj.toUrl({ id: 1 }, { page:1, sort: 'asc' })
   *     returns products/1/detail?page=1&sort=asc
   */
  toUrl = (pathParams?: KV, queryParams?: KV, partial?: boolean) => {
    let url: string = (partial ? this.path : this.fullPath) || '';
    if (pathParams) {
      Object.keys(pathParams).forEach((key: string) => {
        url = url.replace(`:${key.toLocaleLowerCase()}`, pathParams[key]);
      });
    }

    if (queryParams) {
      url = `${url}?${stringify(queryParams)}`;
    }
    return url;
  };
}

export default RouteBase;
