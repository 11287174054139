import pkg from '../../package.json';

const appConfigs = {
  appName: pkg.name,
  version: pkg.version,
  appShortName: 'backoffice',
  miscs: {
    durationTimeout: 4000, // ms
  },
  api: {
    baseURL:
      process.env.REACT_APP__BASE_URL?.concat(
        process.env.REACT_APP__BASE_URL_VERSION || '',
      ) || '',
    retry: 5, //times
    timeout: 5000, //ms
  },
};

export default appConfigs;
