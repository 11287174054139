import createApiEndpoint from 'src/httpClient/endpoints';
import { REQUEST_METHODS } from 'src/httpClient/constants';
import {
  GET_CURRENT_ACCOUNT,
  GET_MENU,
  GET_QR_LOGIN_STRING,
} from 'src/httpClient/endpoints/account/constants';
import { IQRLoginReqModel } from 'src/store/ducks/account/types';

export const getQRLoginStringFetch = (params: IQRLoginReqModel) => {
  return createApiEndpoint(GET_QR_LOGIN_STRING, REQUEST_METHODS.POST, params);
};
export const getCurrentUserFetch = () => {
  return createApiEndpoint(GET_CURRENT_ACCOUNT, REQUEST_METHODS.GET);
};
export const getMenuFetch = params => {
  return createApiEndpoint(GET_MENU, REQUEST_METHODS.GET, params);
};
