import React from 'react';
import { Button, Icon } from 'roadrunner.uiframework';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

export default function FiltersComponent(props) {
  const { title, children, onClose, onClear, ...rest } = props;

  return (
    <FilterContainer>
      <div className='flex items-center justify-between'>
        <span className='text-3xl'>Filtrele</span>
        <CloseIcon>
          <Icon name={'close'} onClick={onClose} />
        </CloseIcon>
      </div>
      <div className='flex flex-col justify-between h-full pt-4'>
        <div className='flex flex-col gap-2'>{children}</div>
        <div className='flex flex-col gap-2 pb-4'>
          <Button
            color='info'
            label='Tüm Filtreleri Uygula'
            elementType='secondary'
          />
          <Button
            color='default'
            label='Tüm Filtreleri Temizle'
            elementType='primary'
            onClick={onClear}
          />
        </div>
      </div>
    </FilterContainer>
  );
}

const CloseIcon = styled.span(() => [
  css`
    ${tw`cursor-pointer`}
    .icon {
      ${tw`text-2xl`}
    }
  `,
]);
const FilterContainer = styled.div(() => [
  css`
    ${tw`h-full p-6`}
    input {
      ${tw`w-full`}
    }
  `,
]);
