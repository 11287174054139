/**
 * @description
 * Generates action names with intellisence support
 * @param keys
 * @param namespace
 * @examples
 * generateDynamicTypes([ 'USER_REQUESTED', 'USER_SUCCESS', 'USER_FAILURE'], 'user'),
 * generateDynamicTypes([ 'USER_REQUESTED', 'USER_SUCCESS', 'USER_FAILURE']),
 *
 * IMPORTANT: if provided, namespace prop is suppose to be **string literal** and not a run time calculated string
 */
function generateDynamicTypes<
  T extends string,
  NS extends string | undefined = undefined,
>(
  keys: T[],
  namespace?: NS,
): { [K in T]: NS extends undefined ? K : `${NS}/${K}` } {
  const namespacePrefix: string = namespace ? `${namespace}/` : '';

  return keys.reduce((res, key) => {
    res[key] = `${namespacePrefix}${key}`;
    return res;
  }, Object.create(null));
}

export default generateDynamicTypes;
