import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import FiltersComponent from 'src/components/Filter';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormTextInput from 'src/components/TextBox';
import FormSelectInput from 'src/components/SelectBox';
import { fetchGetRoleListRequest } from 'src/store/ducks/role/action';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from 'src/store/ducks/types';
import history from 'src/common/history';
import setFilterToParams from 'src/common/helpers/setFilterToParams';
import parseParams from 'src/common/helpers/paramParser';

type IRoleFilter = {
  name?: string;
  type?: string;
  onClose?: Function;
  onSearch?: Function;
};

const schema = yup.object({});

const RoleFilter: React.FC<IRoleFilter> = (props: IRoleFilter) => {
  const queryParams: any = parseParams(
    decodeURIComponent(window.location.search),
  );
  const roleTypeList = useSelector(
    (state: IApplicationState) => state.role.roleTypeList,
  );
  const { handleSubmit, setValue, reset, control } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (queryParams) {
      let roleTypeFromQueryParams = roleTypeList.find(
        item => item.value === queryParams.type,
      );
      reset({
        ...queryParams,
        type: roleTypeFromQueryParams,
      });
    }
  }, []);

  const onSubmit: SubmitHandler<any> = data => {
    let searchParams = parseParams(decodeURIComponent(window.location.search));
    setFilterToParams({ ...searchParams, ...data });
    props.onSearch && props.onSearch({ ...data, ...searchParams });
    props.onClose && props.onClose();
  };

  const onClearFilter: Function = () => {
    history.push('/roller');
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className='h-full'>
        <FiltersComponent onClose={props.onClose} onClear={onClearFilter}>
          <FormTextInput
            control={control}
            name='term'
            placeholder='Rol adı giriniz'
            size='small'
            setValue={setValue}
          />
          <FormSelectInput
            placeholder='Role tipi seçiniz'
            size='small'
            name='type'
            control={control}
            options={[{ value: '', label: 'Seçiniz' }, ...roleTypeList]}
          />
        </FiltersComponent>
      </form>
    </>
  );
};

export default RoleFilter;
