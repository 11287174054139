import { PayloadMetaAction, TypeConstant } from 'typesafe-actions';
import { IGetCourierListReqModel } from './types';
import { all, call, fork, takeEvery } from 'redux-saga/effects';
import { COURIER_ACTION_TYPES } from './constants';
import { getCourierListFetchResolver } from 'src/httpClient/resolvers/courier';

function* handleGetCourierListFetch(
  action: PayloadMetaAction<TypeConstant, IGetCourierListReqModel, unknown>,
): Generator {
  try {
    yield call(getCourierListFetchResolver, action.payload);
  } catch (err: any) {
    let errorMessage = err;
    console.log('errorMessage', errorMessage);
  }
}
function* watchFetchCourierRequest(): Generator {
  yield takeEvery(
    COURIER_ACTION_TYPES.GET_COURIER_LIST,
    handleGetCourierListFetch,
  );
}

export default function* courierSaga(): Generator {
  yield all([fork(watchFetchCourierRequest)]);
}
