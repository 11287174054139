import { PayloadMetaAction, TypeConstant } from 'typesafe-actions';
import {
  ICreateRoleReqModel,
  IDeleteRoleReqModel,
  IGetRoleDetailReqModel,
  IGetRolePermissionReqModel,
  IGetRoleReqModel,
  IRoleListRawModel,
} from './types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ROLE_ACTION_TYPES } from './constants';
import {
  createRoleFetchResolver,
  deleteRoleFetchResolver,
  getPermissionListFetchResolver,
  getRoleFetchResolver,
  getRoleListFetchResolver,
  getRolePermissionFetchResolver,
  getRoleTypeListFetchResolver,
  updateRoleFetchResolver,
} from 'src/httpClient/resolvers/roles';
import {
  fetchSetPermissionList,
  fetchSetRoleDetail,
  fetchSetRoleList,
  fetchSetRolePermissionList,
  fetchSetRoleTypeList,
} from './action';
import { setNotification } from '../app/action';
import history from 'src/common/history';
function* handleGetRoleListFetch(
  action: PayloadMetaAction<TypeConstant, IGetRoleReqModel, unknown>,
): Generator {
  try {
    const res: IRoleListRawModel | any = yield call(
      getRoleListFetchResolver,
      action.payload,
    );
    if (res.data) {
      yield put(fetchSetRoleList(res));
    }
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}
// GET_PERMISSION
function* handleGetPermissionListFetch(): Generator {
  try {
    const res: IRoleListRawModel | any = yield call(
      getPermissionListFetchResolver,
    );
    if (res.data) {
      let tempArray = res.data.map(item => {
        return {
          value: item.id,
          label: item.description,
        };
      });
      yield put(fetchSetPermissionList(tempArray));
    }
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}

// GET_ROLE_TYPES
function* handleGetRoleTypeListFetch(): Generator {
  try {
    const res: IRoleListRawModel | any = yield call(
      getRoleTypeListFetchResolver,
    );
    if (res) {
      let tempArray = res.data.map(item => {
        return {
          value: item.value,
          label: item.text,
        };
      });
      yield put(fetchSetRoleTypeList(tempArray));
    }
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}
// CREATE_ROL
function* handleCreateRoleFetch(
  action: PayloadMetaAction<TypeConstant, ICreateRoleReqModel, unknown>,
): Generator {
  try {
    const res: IRoleListRawModel | any = yield call(
      createRoleFetchResolver,
      action.payload,
    );
    if (res) {
      yield put(
        setNotification({
          message: 'Role başarıyla eklendi.',
          type: 'success',
        }),
      );
      history.push(`/rol/${res.roleId}`);
    }
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}
// UPDATE_ROL
function* handleUpdateRoleFetch(
  action: PayloadMetaAction<TypeConstant, ICreateRoleReqModel, unknown>,
): Generator {
  try {
    const res: IRoleListRawModel | any = yield call(
      updateRoleFetchResolver,
      action.payload,
      action.meta,
    );
    yield put(
      setNotification({
        message: 'Role başarıyla güncellendi.',
        type: 'success',
      }),
    );
  } catch (err: any) {
    let errorMessage = err.message;
    yield put(
      setNotification({
        message: `Role güncellenirken hata oluştu.${errorMessage}`,
        type: 'error',
      }),
    );
  }
}
// GET_ROLE
function* handleGetRoleFetch(
  action: PayloadMetaAction<TypeConstant, IGetRoleDetailReqModel, unknown>,
): Generator {
  try {
    const res: IRoleListRawModel | any = yield call(
      getRoleFetchResolver,
      action.payload,
    );
    if (res) {
      yield put(fetchSetRoleDetail(res.data));
    }
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}

// GET_ROLE_PERMISSIONS
function* handleGetRolePermissionFetch(
  action: PayloadMetaAction<TypeConstant, IGetRolePermissionReqModel, unknown>,
): Generator {
  try {
    const res: IRoleListRawModel | any = yield call(
      getRolePermissionFetchResolver,
      action.payload,
    );
    if (res.data) {
      yield put(fetchSetRolePermissionList(res.data));
    }
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}
// DELETE_ROLE
function* handleDeleteRoleFetch(
  action: PayloadMetaAction<TypeConstant, IDeleteRoleReqModel, unknown>,
): Generator {
  try {
    const res: any = yield call(deleteRoleFetchResolver, action.payload);
    yield put(
      setNotification({
        message: 'Role başarıyla silindi.',
        type: 'success',
      }),
    );
    if (history.location.pathname === '/roller') {
      history.push(history.location.pathname + history.location.search);
    } else {
      history.push('/roller');
    }
  } catch (err: any) {
    let errorMessage = err.message;
    yield put(
      setNotification({
        message: 'Role silinirken hata oluştu.',
        type: 'error',
      }),
    );
  }
}

function* watchFetchRoleMenuRequest(): Generator {
  yield takeEvery(ROLE_ACTION_TYPES.GET_ROLE_LIST, handleGetRoleListFetch);
  yield takeEvery(
    ROLE_ACTION_TYPES.GET_PERMISSION_LIST,
    handleGetPermissionListFetch,
  );
  yield takeEvery(ROLE_ACTION_TYPES.GET_ROLE_TYPES, handleGetRoleTypeListFetch);
  yield takeEvery(ROLE_ACTION_TYPES.CREATE_ROLE, handleCreateRoleFetch);
  yield takeEvery(ROLE_ACTION_TYPES.GET_ROLE, handleGetRoleFetch);
  yield takeEvery(ROLE_ACTION_TYPES.UPDATE_ROLE, handleUpdateRoleFetch);
  yield takeEvery(ROLE_ACTION_TYPES.DELETE_ROLE, handleDeleteRoleFetch);
  yield takeEvery(
    ROLE_ACTION_TYPES.GET_ROLE_PERMISSION_LIST,
    handleGetRolePermissionFetch,
  );
}

export default function* roleSaga(): Generator {
  yield all([fork(watchFetchRoleMenuRequest)]);
}
