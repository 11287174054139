import React from 'react';
import ReactDOM from 'react-dom';
import store from 'src/store';
import Loader from 'src/components/Loader';
import App from 'src/components/App';
import history from 'src/common/history';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<Loader />}>
      <App store={store} history={history} />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
