import { AnyCourierAction } from './action';
import { COURIER_ACTION_TYPES } from './constants';
import { ICourierState } from './types';

export const initialState: ICourierState = {
  courierList: [],
  totalCount: 0,
  filteredCount: 0,
};

export const courierReducer = (
  state: ICourierState = initialState,
  action: AnyCourierAction,
): ICourierState => {
  switch (action.type) {
    case COURIER_ACTION_TYPES.SET_COURIER_LIST:
      return {
        ...state,
        courierList: action.payload.data,
        totalCount: action.payload.totalCount,
        filteredCount: action.payload.filteredCount,
      };
    default:
      return {
        ...state,
      };
  }
};
