import React from 'react';

const NotFound: React.FC = () => {
  return (
    <>
      <h1>NotFound Page</h1>
    </>
  );
};

export default NotFound;
