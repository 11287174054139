// import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AxiosError, AxiosResponse } from 'axios';
import { IResponseInterceptor } from 'src/httpClient/interceptors/types';
import { HTTP_STATUSES } from 'src/httpClient/constants';
import AuthService from 'src/common/auth';
import arrangeCallNamesForLoader from 'src/common/helpers/arrangeCallNamesForLoader';
import bannedList from './bannedRequestForLoader';

const responseInterceptor: IResponseInterceptor = {
  onFulfilled: (response: AxiosResponse) => {
    if (response.config.url) {
      let findIndex = bannedList.findIndex(
        item => item === response.config.url,
      );
      if (findIndex === -1) {
        arrangeCallNamesForLoader(response.config.url);
      }
    }
    return response.data;
  },
  onRejected: (error: AxiosError) => {
    if (error.response?.config.url) {
      let findIndex = bannedList.findIndex(
        item => item === error.response?.config.url,
      );
      if (findIndex === -1) {
        arrangeCallNamesForLoader(error.response?.config.url);
      }
    }
    if (
      error?.response?.status === HTTP_STATUSES.UNAUTHORIZED ||
      (error.code && parseInt(error.code, 10) === HTTP_STATUSES.UNAUTHORIZED)
    ) {
      const auth = new AuthService();
      auth.login();
    } else {
    }
    return Promise.reject(error?.response);
  },
};

export default responseInterceptor;
