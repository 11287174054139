import React from 'react';
import { Button, Icon } from 'roadrunner.uiframework';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

type IItemSummary = {
  title?: React.ReactElement | string;
  tableSide?: React.ReactElement;
  buttonSide?: React.ReactElement;
  bottomSide?: React.ReactElement;
  onSuccessButtonClick?: Function;
  onDefaultButtonClick?: Function;
  onClose?: Function;
  onDefaultButtonText?: string;
  onSuccessButtonText?: string;
  additionalButton?: React.ReactElement;
};

const ItemSummary = (props: IItemSummary) => {
  const {
    title,
    tableSide,
    buttonSide,
    bottomSide,
    onSuccessButtonClick,
    onSuccessButtonText,
    onDefaultButtonClick,
    onDefaultButtonText,
    additionalButton,
    onClose,
  } = props;

  return (
    <SummaryContainer>
      <HeaderContainer>
        <Header>{title}</Header>
        <CloseIcon>
          <Icon name={'close'} onClick={onClose} />
        </CloseIcon>
      </HeaderContainer>
      <TableContainer>{tableSide}</TableContainer>
      <ButtonContainer>
        {buttonSide}
        {(onSuccessButtonText || onDefaultButtonText) && (
          <DefaultButtonsContainer>
            {onSuccessButtonText && (
              <Button
                color='success'
                label={onSuccessButtonText}
                elementType='secondary'
                onClick={onSuccessButtonClick}
              />
            )}
            {onDefaultButtonText && (
              <Button
                color='default'
                label={onDefaultButtonText}
                elementType='primary'
                onClick={onDefaultButtonClick}
              />
            )}
            {additionalButton}
          </DefaultButtonsContainer>
        )}
      </ButtonContainer>
      <BottomContainer>{bottomSide}</BottomContainer>
    </SummaryContainer>
  );
};

export default ItemSummary;

const CloseIcon = styled.span(() => [
  css`
    ${tw`cursor-pointer`}
    .icon {
      ${tw`text-2xl`}
    }
  `,
]);
const HeaderContainer = styled.div(() => [
  tw`flex items-center justify-between p-5`,
]);
const Header = styled.div(() => [tw``]);
const SummaryContainer = styled.div(() => [
  css`
    ${tw`h-full flex flex-col`}
    input {
      ${tw`w-full`}
    }
  `,
]);
const TableContainer = styled.div(() => [tw``]);
const DefaultButtonsContainer = styled.div(() => [tw`flex flex-row gap-2 p-5`]);
const ButtonContainer = styled.div(() => [tw`flex flex-col`]);
const BottomContainer = styled.div(() => [
  tw`border-top[1px solid] border-gray-100`,
]);
