import React, { useEffect, useState } from 'react';
import { LayoutProps } from '../types';
import AuthService from 'src/common/auth';
import Header from 'src/components/Header';
import QuickSearchBar from 'src/components/QuickSearchBar';
import Loader from 'src/components/Loader';
import { Toast } from 'roadrunner.uiframework';
import { useSelector } from 'react-redux';
import { IApplicationState } from 'src/store/ducks/types';
import { toast } from 'react-toastify';

type MainLayoutPropTypes = {} & LayoutProps;

const MainLayout: React.FC<MainLayoutPropTypes> = ({
  children,
}: MainLayoutPropTypes) => {
  const auth = new AuthService();
  const [loaderState, setLoaderState] = useState(true);
  const appState = useSelector((state: IApplicationState) => state.app);
  const Identity_Domain = process.env.REACT_APP__IDENTITY_DOMAIN;
  useEffect(() => {
    const savedTokenString = localStorage.getItem(
      `oidc.user:${Identity_Domain}:backofficejs`,
    );
    if (savedTokenString) {
      const savedToken = JSON.parse(savedTokenString);
      const tokenExpireDate = new Date(savedToken?.expires_at * 1000);
      const now = new Date();
      if (now > tokenExpireDate) {
        localStorage.removeItem(`oidc.user:${Identity_Domain}:backofficejs`);
        goAuth();
      } else {
        setLoaderState(false);
        localStorage.setItem('currentUser', JSON.stringify(savedToken.profile));
        localStorage.setItem('token', savedToken.access_token);
      }
    } else {
      localStorage.removeItem(`oidc.user:${Identity_Domain}:backofficejs`);
      goAuth();
    }
  }, []);

  const goAuth = () => {
    auth.getUser().then((user: any) => {
      if (!user) {
        auth.login();
      }
    });
  };
  useEffect(() => {
    appState.notification.message &&
      toast(appState.notification.message, {
        type: appState.notification.type,
        autoClose: 3000,
      });
  }, [appState.notification]);

  return (
    <>
      {(appState.activeCalls?.length > 0 || loaderState) && <Loader />}
      {!loaderState && (
        <>
          <Toast full={appState.notification.full} />
          <Header />
          <QuickSearchBar />
          {children}
        </>
      )}
    </>
  );
};

export default MainLayout;
