import React, { useEffect, useRef, useState } from 'react';
import tw from 'twin.macro';
import styled, { css } from 'styled-components';
import { Button, PageTitle, Icon, Labels } from 'roadrunner.uiframework';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from 'src/store/ducks/types';
import history from 'src/common/history';
import parseParams from 'src/common/helpers/paramParser';
import Table from 'src/components/Table';
import {
  fetchDeleteRoleDetail,
  fetchGetRoleListRequest,
  fetchGetRoleTypeList,
} from 'src/store/ducks/role/action';
import RoleFilter from './filter';
import setFilterToParams from 'src/common/helpers/setFilterToParams';

const RolList: React.FC = (props: any) => {
  let searchParams = parseParams(decodeURIComponent(window.location.search));
  const roleList = useSelector(
    (state: IApplicationState) => state.role.roleList,
  );
  const [queryParam, setQueryParam] = useState({});
  let pageIndex = searchParams && searchParams.Take / 10 - 1;
  let initialSortBy =
    searchParams && searchParams.OrderBy
      ? [{ id: searchParams.OrderBy, desc: searchParams.IsDescending }]
      : [];
  const roleListCount = useSelector(
    (state: IApplicationState) => state.role.filteredCount,
  );
  const roleTypeList = useSelector(
    (state: IApplicationState) => state.role.roleTypeList,
  );
  const dispatch: Dispatch = useDispatch();
  const rolTableColumns = [
    {
      Header: 'Rol Adı',
      sticky: 'left',
      width: 150,
      accessor: 'name',
      Cell: row => {
        return (
          <AnchorSpan
            onClick={() => {
              editRole(row.cell.row.original.roleId);
            }}
          >
            {row.cell.value}
          </AnchorSpan>
        );
      },
    },
    {
      Header: 'Rol Tipi',
      width: 100,
      accessor: 'type',
      Cell: row => {
        let typeName = roleTypeList.find(
          element => element.value == String(row.cell.value),
        );
        let displayName = typeName ? typeName.label : '';
        return <span>{displayName}</span>;
      },
    },
    {
      Header: 'Yetkiler',
      accessor: '',
      disableSortBy: true,
    },
    {
      Header: (
        <div className='pl-1'>
          <Icon className='color-grey-500 medium-24' name='arrow-right' />
        </div>
      ),
      id: 'action',
      width: 40,
      disableSortBy: true,
      sticky: 'right',
      accessor: row => {
        return (
          <>
            {/* <Button
              icon='delete'
              elementType='primary'
              onClick={() => showConfirmationModal(row.roleId)}
            /> */}
          </>
        );
      },
    },
  ];
  const newRole = () => {
    history.push('/rol');
  };
  const editRole = roleId => {
    history.push(`/rol/${roleId}`);
  };

  useEffect(() => {
    dispatch(fetchGetRoleTypeList());
  }, []);

  useEffect(() => {
    getRoles(searchParams);
  }, [queryParam]);

  const getRoles = (params: any) => {
    dispatch(fetchGetRoleListRequest(params)); // TODO: Take default 50, SS Search edelim.
  };

  const getRoleWithTableProps = tableProps => {
    let searchParams = parseParams(decodeURIComponent(window.location.search));
    if (tableProps) {
      let apiProps = {
        ...searchParams,
        Take: tableProps.pageIndex ? (tableProps.pageIndex + 1) * 10 : 10,
        Skip: tableProps.pageIndex ? tableProps.pageIndex * 10 : 0,
        OrderBy:
          tableProps.sortBy.length > 0 ? tableProps.sortBy[0].id : undefined,
        IsDescending:
          tableProps.sortBy.length > 0 ? tableProps.sortBy[0].desc : undefined,
      };
      setFilterToParams(apiProps);
      setQueryParam(apiProps);
    }
  };

  //Listedeki filtreme modali
  const [showFilter, hideFilter] = useModal(() => (
    <ReactModal
      isOpen
      ariaHideApp={false}
      style={filterStyle}
      onRequestClose={hideFilter}
    >
      <RoleFilter onClose={hideFilter} onSearch={setQueryParam} />
    </ReactModal>
  ));

  return (
    <>
      <PageTitleContainer>
        <PageTitle
          title='Roller'
          icon={
            <Link to='/'>
              <Icon name='arrow-left' />
            </Link>
          }
        >
          <TitleButtonContainer>
            <Button
              color='default'
              icon='filter'
              label='Filtrele'
              elementType='primary'
              onClick={showFilter}
            />
            <Button
              color='success'
              icon=''
              label='Yeni Rol'
              elementType='secondary'
              onClick={() => newRole()}
            />
          </TitleButtonContainer>
        </PageTitle>
      </PageTitleContainer>
      <Table
        columns={rolTableColumns}
        data={roleList}
        manualPagination={true}
        manualSort={true}
        fetchData={props => getRoleWithTableProps(props)}
        totalRowCount={roleListCount}
        initialPageIndex={pageIndex}
        initialSortBy={initialSortBy}
      />
    </>
  );
};

export default RolList;

const PageTitleContainer = styled.div(() => [
  tw`flex items-center box-shadow[0px 2px 12px rgba(0, 0, 0, 0.03)] mt-4`,
]);
const TitleButtonContainer = styled.div(() => [
  css`
    ${tw`flex items-center gap-2`}
    button {
      .icon {
        ${tw`text-base`}
      }
    }
  `,
]);
const filterStyle = {
  overlay: {
    position: 'fixed',
    opacity: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(34, 43, 69, 0.5)',
    zIndex: 10,
  },
  content: {
    height: '100%',
    width: '370px',
    position: 'absolute',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    right: 0,
    left: 'auto',
    bottom: 0,
    top: 0,
    borderRadius: '0px !important',
    padding: '0px !important',
  },
};
const AnchorSpan = styled.span(() => [
  tw`text-decoration[underline] cursor-pointer hover:text-info-500`,
]);
