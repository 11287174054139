import history from 'src/common/history';

const setQueryParamstoUrl = (queryParams): any => {
  window.history.pushState(
    '',
    '',
    window.location.pathname + `?${queryParams}`,
  );
};

export default setQueryParamstoUrl;
