import React, { Suspense } from 'react';
import Loader from '../Loader';
import { RouteComponentProps } from 'react-router-dom';

const withSuspense =
  (WrappedComponent: React.ComponentType<RouteComponentProps<any>>) =>
  (props: any) =>
    (
      <Suspense fallback={<Loader />}>
        <WrappedComponent {...props} />
      </Suspense>
    );

export default withSuspense;
