import React from 'react';
import { Controller } from 'react-hook-form';
import { TextBox } from 'roadrunner.uiframework';

export default function FormTextInput(props) {
  const {
    placeholder,
    size = 'small',
    disabled,
    rule,
    name,
    control,
    value,
    rules,
    ...rest
  } = props;
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <TextBox placeholder={placeholder} size={size} {...field} {...rest} />
        )}
      />
    </>
  );
}
