import history from 'src/common/history';
import setQueryParamstoUrl from './setQueryParamstoUrl';

const setFilterToParams = (data): any => {
  const urlParams: any = new URLSearchParams(window.location.search);
  for (const item in data) {
    if (typeof data[item] === 'object') {
      urlParams.delete(item);
      if (data[item].length) {
        data[item].map(subItem => {
          urlParams.append(item, subItem.value);
        });
      } else {
        data[item].value !== undefined &&
        data[item].value !== null &&
        data[item].value !== ''
          ? urlParams.set(item, data[item].value)
          : urlParams.delete(item);
      }
    } else {
      data[item] !== undefined && data[item] !== null && data[item] !== ''
        ? urlParams.set(item, data[item])
        : urlParams.delete(item);
    }
  }
  setQueryParamstoUrl(urlParams);
};

export default setFilterToParams;
