import { PayloadMetaAction, TypeConstant } from 'typesafe-actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SERVICE_ACTION_TYPES } from './constants';
import {
  IGetServiceListReqModel,
  IServiceDetailReqModel,
  IServiceRawModel,
} from './types';
import {
  createServiceFetchResolver,
  deleteServiceFetchResolver,
  getServiceCollectingTypeFetchResolver,
  getServiceDeliveryTimeFetchResolver,
  getServiceDetailFetchResolver,
  getServiceListFetchResolver,
  getServiceTransferTypeFetchResolver,
  updateServiceFetchResolver,
} from 'src/httpClient/resolvers/service';
import {
  fetchSetDropDownsValues,
  fetchSetServiceDetail,
  fetchSetServiceList,
} from './action';
import { setNotification } from '../app/action';
import history from 'src/common/history';
function* handleGetServiceListFetch(
  action: PayloadMetaAction<TypeConstant, IGetServiceListReqModel, unknown>,
): Generator {
  try {
    const res: any = yield call(getServiceListFetchResolver, action.payload);
    if (res) {
      yield put(fetchSetServiceList(res));
    } else {
      console.log('handleGetServiceListFetch response gelmedi');
    }
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}

function* handleGetServiceDetailFetch(
  action: PayloadMetaAction<TypeConstant, IServiceDetailReqModel, unknown>,
): Generator {
  try {
    const res: any = yield call(getServiceDetailFetchResolver, action.payload);
    if (res) {
      yield put(fetchSetServiceDetail(res.data));
    } else {
      console.log('handleGetServiceListFetch response gelmedi');
    }
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}

function* handleGetServiceDropDownFetch(): Generator {
  try {
    const dummyDeliveryTime: any = yield call(
      getServiceDeliveryTimeFetchResolver,
    );
    const dummyCollectingTypes: any = yield call(
      getServiceCollectingTypeFetchResolver,
    );
    const dummyTransferTypes: any = yield call(
      getServiceTransferTypeFetchResolver,
    );
    let deliveryTime: any = [];
    let collectingTypes: any = [];
    let transferTypes: any = [];
    for (const property in dummyDeliveryTime) {
      deliveryTime.push({
        value: Number(property),
        label: dummyDeliveryTime[property],
      });
    }
    for (const property in dummyCollectingTypes) {
      collectingTypes.push({
        value: Number(property),
        label: dummyCollectingTypes[property],
      });
    }
    for (const property in dummyTransferTypes) {
      transferTypes.push({
        value: Number(property),
        label: dummyTransferTypes[property],
      });
    }
    yield put(
      fetchSetDropDownsValues({ deliveryTime, collectingTypes, transferTypes }),
    );
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}
function* handleUpdateServiceFetch(
  action: PayloadMetaAction<TypeConstant, IServiceRawModel, unknown>,
): Generator {
  try {
    yield call(updateServiceFetchResolver, action.payload, action.meta);
    yield put(
      setNotification({
        message: 'Servis başarıyla güncellendi.',
        type: 'success',
      }),
    );
  } catch (err: any) {
    let errorMessage = err;
    yield put(
      setNotification({
        message:
          'Servis güncellenirken hata oluştu.' +
          errorMessage.data.errors[''][0].description,
        type: 'error',
      }),
    );
  }
}

function* handleCreateServiceFetch(
  action: PayloadMetaAction<TypeConstant, IServiceRawModel, unknown>,
): Generator {
  try {
    const res: any = yield call(createServiceFetchResolver, action.payload);
    if (res) {
      yield put(
        setNotification({
          message: 'Hizmet başarıyla eklendi.',
          type: 'success',
        }),
      );
      history.push(`/hizmet/${res.id}`);
    }
  } catch (err: any) {
    let errorMessage = err;
    yield put(
      setNotification({
        message: `Hizmet eklenirken hata oluştu. Hata : ${errorMessage.data.errors[''][0].description}`,
        type: 'warning',
      }),
    );
  }
}
function* handleDeleteServiceFetch(
  action: PayloadMetaAction<TypeConstant, IServiceDetailReqModel, unknown>,
): Generator {
  try {
    const res: any = yield call(deleteServiceFetchResolver, action.payload);
    yield put(
      setNotification({
        message: 'Servis başarıyla silindi.',
        type: 'success',
      }),
    );
    history.push(history.location.pathname + history.location.search);
  } catch (err: any) {
    let errorMessage = err;
    yield put(
      setNotification({
        message: 'Servis silinirken hata oluştu.',
        type: 'error',
      }),
    );
  }
}

function* watchFetchServiceRequest(): Generator {
  yield takeEvery(
    SERVICE_ACTION_TYPES.GET_SERVICE_LIST,
    handleGetServiceListFetch,
  );
  yield takeEvery(
    SERVICE_ACTION_TYPES.GET_SERVICE_DETAIL,
    handleGetServiceDetailFetch,
  );
  yield takeEvery(
    SERVICE_ACTION_TYPES.GET_SERVICE_DROPDOWNS,
    handleGetServiceDropDownFetch,
  );
  yield takeEvery(
    SERVICE_ACTION_TYPES.UPDATE_SERVICE,
    handleUpdateServiceFetch,
  );
  yield takeEvery(
    SERVICE_ACTION_TYPES.CREATE_SERVICE,
    handleCreateServiceFetch,
  );
  yield takeEvery(
    SERVICE_ACTION_TYPES.DELETE_SERVICE,
    handleDeleteServiceFetch,
  );
}

export default function* serviceSaga(): Generator {
  yield all([fork(watchFetchServiceRequest)]);
}
