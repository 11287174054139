import createApiEndpoint from 'src/httpClient/endpoints';
import { REQUEST_METHODS } from 'src/httpClient/constants';
import {
  IDeleteUserReqModel,
  IGetUserActivitiesReqModel,
  IGetUserAutocompleteListReqModel,
  IGetUserListReqModel,
  IGetUserReqModel,
  IGetUserRolesReqModel,
  ISendUserMailConfirmationReqModel,
  ISendUserSMSConfirmationReqModel,
  ISetUserReqModel,
  ISetUserStatusReqModel,
} from 'src/store/ducks/user/types';
import {
  DELETE_USER,
  GET_USER,
  GET_USER_ACTIVITIES,
  GET_USER_AUTOCOMPLETE_LIST,
  GET_USER_LIST,
  GET_USER_STATISTIC,
  SET_USER,
  UPDATE_USER,
} from './constants';

export const getUserAutoCompleteListFetch = (
  params: IGetUserAutocompleteListReqModel,
) => {
  return createApiEndpoint(
    GET_USER_AUTOCOMPLETE_LIST,
    REQUEST_METHODS.GET,
    {},
    params,
  );
};
export const getUserListFetch = (params: IGetUserListReqModel) => {
  return createApiEndpoint(GET_USER_LIST, REQUEST_METHODS.GET, {}, params);
};
export const getUserStatisticFetch = () => {
  return createApiEndpoint(GET_USER_STATISTIC, REQUEST_METHODS.GET);
};
export const setUserFetch = (payload: ISetUserReqModel) => {
  return createApiEndpoint(SET_USER, REQUEST_METHODS.POST, payload);
};
export const deleteUserFetch = (params: IDeleteUserReqModel) => {
  return createApiEndpoint(
    DELETE_USER + `/${params.userid}/false`,
    REQUEST_METHODS.DELETE,
  );
};
export const getUserFetch = (params: IGetUserReqModel) => {
  return createApiEndpoint(GET_USER + `/${params.userid}`, REQUEST_METHODS.GET);
};
export const getUserRolesFetch = (params: IGetUserRolesReqModel) => {
  return createApiEndpoint(
    GET_USER + `/${params.userid}/roles`,
    REQUEST_METHODS.GET,
  );
};
export const getUserActivitiesFetch = (params: IGetUserActivitiesReqModel) => {
  return createApiEndpoint(
    GET_USER_ACTIVITIES + `/${params.userid}/activities`,
    REQUEST_METHODS.GET,
  );
};
export const updateUserFetch = (
  payload: ISetUserReqModel,
  params: IGetUserRolesReqModel,
) => {
  return createApiEndpoint(
    UPDATE_USER + `/${params.userid}`,
    REQUEST_METHODS.PUT,
    payload,
  );
};
export const setUserStatusFetch = (payload: ISetUserStatusReqModel) => {
  return createApiEndpoint(
    UPDATE_USER + `/${payload.userid}/status/${payload.statusId}`,
    REQUEST_METHODS.PATCH,
  );
};
export const sendUserMailConfirmationFetch = (
  payload: ISendUserMailConfirmationReqModel,
) => {
  return createApiEndpoint(
    UPDATE_USER + `/${payload.userid}/confirmation/email`,
    REQUEST_METHODS.POST,
  );
};
export const sendUserSMSConfirmationFetch = (
  payload: ISendUserSMSConfirmationReqModel,
) => {
  return createApiEndpoint(
    UPDATE_USER + `/${payload.userid}/confirmation/sms`,
    REQUEST_METHODS.POST,
  );
};
