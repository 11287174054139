import { useEffect } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { SearchItemContainer } from 'roadrunner.uiframework';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { fetchGetUserAutoCompleteList } from 'src/store/ducks/user/action';
import { IApplicationState } from 'src/store/ducks/types';
import history from 'src/common/history';
import { Link } from 'react-router-dom';

function QuickSearchBar() {
  const dispatch: Dispatch = useDispatch();
  const userState = useSelector((state: IApplicationState) => state.user);

  useEffect(() => {
    dispatch(fetchGetUserAutoCompleteList({ Term: '', Take: 4 }));
  }, []);
  let doUserSearch = searchKey => {
    dispatch(fetchGetUserAutoCompleteList({ Term: searchKey, Take: 4 }));
  };
 
  const userNavigation = (route, id) => {
    history.push(`/${route}/${id}`);
  };
  return (
    <QuickSearchBarContainer>
      <SearchItemContainer
        searchData={userState.userListAutoComplete}
        search={searchKey => doUserSearch(searchKey)}
        text='Kullanıcı Ara'
        icon='user'
        placeHolder='Kullanıcı Bilgisi'
        onItemsClick={id => userNavigation('kullanici', id)}
        bottomUrl={<Link to='/kullanicilar'>Tüm Kullanıcılar</Link>}
      />
{/*       
      <SearchItemContainer
        searchData={courierState.courierList}
        search={searchKey => doCourierSearch(searchKey)}
        text='Kurye Ara'
        icon='search-vendor'
        placeHolder='Kurye Bilgisi'
        onItemsClick={id => userNavigation('courier', id)}
        bottomUrl={<Link to='/'>Tüm Kuryeler</Link>}
      />
      <SearchItemContainer
        searchData={vendorState.vendorList}
        search={searchKey => doVendorSearch(searchKey)}
        text='Vendor Ara'
        icon='company'
        placeHolder='Vendor Bilgisi'
        onItemsClick={id => userNavigation('vendor', id)}
        bottomUrl={<Link to='/'>Tüm Vendorlar</Link>}
      /> */}
      
    </QuickSearchBarContainer>
  );
}

export default QuickSearchBar;

const QuickSearchBarContainer = styled.div(() => [
  tw`bg-white-500 grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 margin-top[45px] box-shadow[0px 2px 12px rgba(0, 0, 0, 0.03)] border-bottom[1px solid] border-grey-200`,
]);
