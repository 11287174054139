import createApiEndpoint from 'src/httpClient/endpoints';
import { REQUEST_METHODS } from 'src/httpClient/constants';
import { IGetCourierListReqModel } from 'src/store/ducks/courier/types';
import { GET_VENDOR_LIST } from './constants';
import { createHttpClient } from 'src/httpClient';
import { AxiosInstance } from 'axios';
import {
  requestInterceptor,
  responseInterceptor,
} from 'src/httpClient/interceptors';

const vendorHttpClient: AxiosInstance = createHttpClient(
  {
    baseURL: process.env.REACT_APP__VENDOR_DOMAIN?.concat(
      process.env.REACT_APP__BASE_URL_VERSION || '',
    ),
  },
  requestInterceptor,
  responseInterceptor,
);

export const getVendorListFetch = (params: IGetCourierListReqModel) => {
  return createApiEndpoint(
    GET_VENDOR_LIST,
    REQUEST_METHODS.GET,
    null,
    params,
    {},
    vendorHttpClient,
  );
};
