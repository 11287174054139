import { action } from 'typesafe-actions';
import { IAccountRawModel } from '../account/types';
import { USER_ACTION_TYPES } from './constants';
import {
  IDeleteUserReqModel,
  IGetUserActivitiesReqModel,
  IGetUserAutocompleteListReqModel,
  IGetUserListReqModel,
  IGetUserReqModel,
  ISendUserMailConfirmationReqModel,
  ISetUserActivitiesModel,
  ISetUserListRawModel,
  ISetUserReqModel,
  ISetUserStatusReqModel,
} from './types';

export const fetchGetUserList = (params: IGetUserListReqModel) =>
  action(USER_ACTION_TYPES.GET_USER_LIST, params);
export const fetchSetUserList = (params: ISetUserListRawModel) =>
  action(USER_ACTION_TYPES.SET_USER_LIST, params);
export const fetchGetUserAutoCompleteList = (
  params: IGetUserAutocompleteListReqModel,
) => action(USER_ACTION_TYPES.GET_USER_AUTOCOMPLETE_LIST, params);
export const fetchSetUserAutoCompleteList = (params: ISetUserListRawModel) =>
  action(USER_ACTION_TYPES.SET_USER_AUTOCOMPLETE_LIST, params);
export const fetchGetUsersStatistic = () =>
  action(USER_ACTION_TYPES.GET_USER_STATISTIC);
export const fetchGetUsersStatisticSuccess = (params: any) =>
  action(USER_ACTION_TYPES.SET_USER_STATISTIC, params);
export const fetchSetUser = (payload: ISetUserReqModel) =>
  action(USER_ACTION_TYPES.SET_USER, payload);
export const fetchGetUserSuccess = (payload: IAccountRawModel) =>
  action(USER_ACTION_TYPES.GET_USER_SUCCESS, payload);
export const fetchGetUser = (params: IGetUserReqModel) =>
  action(USER_ACTION_TYPES.GET_USER, params);
export const fetchUpdateUser = (
  payload: ISetUserReqModel,
  params: IGetUserReqModel,
) => action(USER_ACTION_TYPES.UPDATE_USER, payload, params);
export const fetchDeleteUser = (payload: IDeleteUserReqModel) =>
  action(USER_ACTION_TYPES.DELETE_USER, payload);
export const setSelectedUserID = (userId: string) =>
  action(USER_ACTION_TYPES.SET_SELECTED_USER_ID, userId);
export const getUserActivities = (params: IGetUserActivitiesReqModel) =>
  action(USER_ACTION_TYPES.GET_USER_ACTIVITIES, params);
export const setUserActivities = (params: ISetUserActivitiesModel) =>
  action(USER_ACTION_TYPES.SET_USER_ACTIVITIES, params);
export const setUserStatus = (params: ISetUserStatusReqModel) =>
  action(USER_ACTION_TYPES.SET_USER_STATUS, params);
export const sendUserEmailConfirmation = (
  params: ISendUserMailConfirmationReqModel,
) => action(USER_ACTION_TYPES.SEND_USER_EMAIL_CONFIRMATION, params);
export const sendUserSmsConfirmation = (
  params: ISendUserMailConfirmationReqModel,
) => action(USER_ACTION_TYPES.SEND_USER_SMS_CONFIRMATION, params);
export const clearUserDetail = () =>
  action(USER_ACTION_TYPES.CLEAR_USER_DETAIL);

export type AnyUserAction =
  | ReturnType<typeof fetchGetUserList>
  | ReturnType<typeof fetchSetUserList>
  | ReturnType<typeof fetchGetUsersStatistic>
  | ReturnType<typeof fetchGetUsersStatisticSuccess>
  | ReturnType<typeof fetchGetUserAutoCompleteList>
  | ReturnType<typeof fetchSetUserAutoCompleteList>
  | ReturnType<typeof fetchSetUser>
  | ReturnType<typeof fetchGetUserSuccess>
  | ReturnType<typeof fetchGetUser>
  | ReturnType<typeof fetchDeleteUser>
  | ReturnType<typeof setSelectedUserID>
  | ReturnType<typeof getUserActivities>
  | ReturnType<typeof clearUserDetail>
  | ReturnType<typeof setUserActivities>
  | ReturnType<typeof setUserStatus>
  | ReturnType<typeof sendUserEmailConfirmation>
  | ReturnType<typeof sendUserSmsConfirmation>
  | ReturnType<typeof fetchUpdateUser>;
