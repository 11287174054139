import generateDynamicTypes from 'src/common/helpers/generateDynamicTypes';

export const SERVICE_ACTION_TYPES = {
  ...generateDynamicTypes(
    [
      'GET_SERVICE_LIST',
      'SET_SERVICE_LIST',
      'GET_SERVICE_DETAIL',
      'SET_SERVICE_DETAIL',
      'GET_SERVICE_DROPDOWNS',
      'SET_SERVICE_DROPDOWNS',
      'CLEAR_SERVICE_DETAIL',
      'UPDATE_SERVICE',
      'CREATE_SERVICE',
      'DELETE_SERVICE',
    ],
    '@@service',
  ),
};
