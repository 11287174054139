import { AnyAccountAction } from './action';
import { SERVICE_ACTION_TYPES } from './constants';
import { IServiceState } from './types';

export const initialState: IServiceState = {
  serviceList: [],
  serviceListTotalCount: 0,
  serviceDetail: {},
  deliveryTime: {},
  collectingTypes: {},
  transferTypes: {},
};

export const serviceReducer = (
  state: IServiceState = initialState,
  action: AnyAccountAction,
): IServiceState => {
  switch (action.type) {
    case SERVICE_ACTION_TYPES.SET_SERVICE_LIST:
      return {
        ...state,
        serviceList: action.payload.data,
        serviceListTotalCount: action.payload.filteredCount,
      };
    case SERVICE_ACTION_TYPES.SET_SERVICE_DETAIL:
      return {
        ...state,
        serviceDetail: action.payload,
      };
    case SERVICE_ACTION_TYPES.CLEAR_SERVICE_DETAIL:
      return {
        ...state,
        serviceDetail: {},
      };
    case SERVICE_ACTION_TYPES.SET_SERVICE_DROPDOWNS:
      return {
        ...state,
        deliveryTime: action.payload.deliveryTime,
        collectingTypes: action.payload.collectingTypes,
        transferTypes: action.payload.transferTypes,
      };
    default:
      return {
        ...state,
      };
  }
};
