import { AxiosError, AxiosResponse } from 'axios';

import {
  getQRLoginStringFetch,
  getCurrentUserFetch,
  getMenuFetch,
} from 'src/httpClient/endpoints/account';
import {
  IQRLoginReqModel,
  IAccountState,
  IGetMenuReqModel,
} from 'src/store/ducks/account/types';

export const getCurrentUserFetchResolver: () => Promise<
  AxiosResponse<IAccountState>
> = () => {
  return getCurrentUserFetch()
    .then((res: AxiosResponse<IAccountState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const getQRLoginStringFetchResolver: (
  params: IQRLoginReqModel,
) => Promise<AxiosResponse<IAccountState>> = (params: IQRLoginReqModel) => {
  return getQRLoginStringFetch(params)
    .then((res: AxiosResponse<IAccountState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const getMenuFetchResolver: (
  params: IGetMenuReqModel,
) => Promise<AxiosResponse<IAccountState>> = (params: IGetMenuReqModel) => {
  return getMenuFetch(params)
    .then((res: AxiosResponse<IAccountState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
