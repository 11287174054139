import React, { useEffect, useState, useRef } from 'react';
import tw from 'twin.macro';
import styled, { css } from 'styled-components';
import { Button, PageTitle, Icon, ConfirmModal } from 'roadrunner.uiframework';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
import { Dispatch } from 'redux';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { IApplicationState } from 'src/store/ducks/types';
import { fetchDeleteUser } from 'src/store/ducks/user/action';
import history from 'src/common/history';
import parseParams from 'src/common/helpers/paramParser';
import Table from 'src/components/Table';
import setFilterToParams from 'src/common/helpers/setFilterToParams';
import modalCloser from 'src/common/helpers/modalCloser';
import ServiceSummary from './serviceSummary';
import ServiceFilter from './filter';
import {
  fetchDeleteService,
  fetchGetServiceList,
} from 'src/store/ducks/service/action';

const HizmetList: React.FC = (props: any) => {
  const serviceList = useSelector(
    (state: IApplicationState) => state.service.serviceList,
    shallowEqual,
  );
  const serviceListTotalCount = useSelector(
    (state: IApplicationState) => state.service.serviceListTotalCount,
    shallowEqual,
  );

  const dispatch: Dispatch = useDispatch();
  let searchParams = parseParams(decodeURIComponent(window.location.search));
  const deletedRowId: any = useRef();
  const selectedRowId: any = useRef();
  const [queryParam, setQueryParam] = useState({});
  let pageIndex = searchParams && searchParams.Take / 10 - 1;
  let initialSortBy =
    searchParams && searchParams.OrderBy
      ? [{ id: searchParams.OrderBy, desc: searchParams.IsDescending }]
      : [];
  const serviceTableColumns = [
    {
      Header: 'Hizmet Adı',
      sticky: 'left',
      accessor: 'name',
      Cell: row => {
        return (
          <AnchorSpan
            onClick={() => {
              anchorSummary(row.cell.row.original.id);
            }}
          >
            {row.cell.row.original.name}
          </AnchorSpan>
        );
      },
    },
    {
      Header: 'Kısa Adı',
      accessor: 'shortName',
    },
    {
      Header: 'Hizmet Kodu',
      accessor: 'serviceCode',
    },
    {
      Header: 'Teslimat Süresi',
      accessor: 'deliveryTimeType',
      Cell: row => {
        return <span>{row.cell.row.original.deliveryTimeDescription}</span>;
      },
    },
    {
      Header: 'Tekrar Dağıtım Adedi',
      accessor: 'retryPolicy',
    },
    {
      Header: 'Toplama Şekli',
      accessor: 'collectingType',
      Cell: row => {
        return <span>{row.cell.row.original.collectingTypeDescription}</span>;
      },
    },
    {
      Header: 'Dağıtım Şekli',
      accessor: 'transferType',
      Cell: row => {
        return <span>{row.cell.row.original.transferTypeDescription}</span>;
      },
    },
    {
      Header: <Icon className='color-grey-500 medium-24' name='arrow-right' />,
      id: 'action',
      width: 60,
      sticky: 'right',
      disableSortBy: true,
      accessor: row => {
        return (
          <>
            <Button
              icon='delete'
              elementType='primary'
              onClick={() => showConfirmationModal(row.id)}
            />
          </>
        );
      },
    },
  ];
  const newService = () => {
    history.push('/hizmet');
  };
  const showConfirmationModal = (id: string) => {
    deletedRowId.current = id;
    showWarning();
  };

  const anchorSummary = id => {
    selectedRowId.current = id;
    showServiceDetail();
  };

  const deleteService = () => {
    deletedRowId.current &&
      dispatch(fetchDeleteService({ id: deletedRowId.current }));
  };

  useEffect(() => {
    searchParams && getServices(searchParams);
  }, [queryParam]);

  const getServices = (params: any) => {
    dispatch(fetchGetServiceList(params));
  };

  const getUserWithTableProps = tableProps => {
    let searchParams = parseParams(decodeURIComponent(window.location.search));
    if (tableProps) {
      let apiProps = {
        ...searchParams,
        Take: tableProps.pageIndex ? (tableProps.pageIndex + 1) * 10 : 10,
        Skip: tableProps.pageIndex ? tableProps.pageIndex * 10 : 0,
        OrderBy:
          tableProps.sortBy.length > 0 ? tableProps.sortBy[0].id : undefined,
        IsDescending:
          tableProps.sortBy.length > 0 ? tableProps.sortBy[0].desc : undefined,
      };
      setFilterToParams(apiProps);
      setQueryParam(apiProps);
    }
  };

  // Delete butonuna basildiginda, cikan uyari modal
  const [showWarning, hideWarning] = useModal(() => (
    <ReactModal
      isOpen
      ariaHideApp={false}
      style={modalStyle}
      onRequestClose={hideWarning}
      shouldReturnFocusAfterClose={false}
    >
      <ConfirmModal
        title={'Kayıt Silme'}
        color='danger'
        icon='close'
        text={'Kaydı silmek istediğinize emin misiniz?'}
        onClose={hideWarning}
        primaryButtonText='Sil'
        primaryButtonElementType='secondary'
        primaryButtonElementColor='danger'
        onPrimaryButtonClick={() => {
          deleteService();
          hideWarning();
        }}
        secondaryButtonText='Vazgeç'
        secondaryButtonElementType='primary'
        secondaryButtonElementColor='default'
        onSecondaryButtonClick={hideWarning}
      />
    </ReactModal>
  ));
  //Listedeki filtreme modali
  const [showFilter, hideFilter] = useModal(e => (
    <ReactModal
      isOpen
      closeTimeoutMS={1000}
      // ariaHideApp={false}
      style={filterStyle}
      onRequestClose={() => modalCloser(hideFilter)}
    >
      <ServiceFilter
        onClose={() => modalCloser(hideFilter)}
        onSearch={setQueryParam}
      />
    </ReactModal>
  ));
  //Listedeki kullanicinin adina tiklandiginda acilan summary modal
  const [showServiceDetail, hideServiceDetail] = useModal(props => (
    <ReactModal
      isOpen
      ariaHideApp={false}
      style={userSummaryStyle}
      closeTimeoutMS={1000}
      onRequestClose={() => modalCloser(hideServiceDetail)}
    >
      <ServiceSummary
        onClose={() => modalCloser(hideServiceDetail)}
        selectedID={selectedRowId.current}
      />
    </ReactModal>
  ));

  return (
    <>
      <PageTitleContainer>
        <PageTitle
          title='Hizmetler'
          icon={
            <Link to='/'>
              <Icon name='arrow-left' />
            </Link>
          }
        >
          <TitleButtonContainer>
            <Button
              color='default'
              icon='filter'
              label='Filtrele'
              elementType='primary'
              onClick={showFilter}
            />
            <Button
              color='success'
              icon=''
              label='Yeni Hizmet'
              elementType='secondary'
              onClick={() => newService()}
            />
          </TitleButtonContainer>
        </PageTitle>
      </PageTitleContainer>
      <Table
        columns={serviceTableColumns}
        data={serviceList}
        manualPagination={true}
        manualSort={true}
        fetchData={props => getUserWithTableProps(props)}
        totalRowCount={serviceListTotalCount}
        initialPageIndex={pageIndex}
        initialSortBy={initialSortBy}
      />
    </>
  );
};

export default HizmetList;

const PageTitleContainer = styled.div(() => [
  tw`flex items-center box-shadow[0px 2px 12px rgba(0, 0, 0, 0.03)] mt-4`,
]);
const TitleButtonContainer = styled.div(() => [
  css`
    ${tw`flex items-center gap-2`}
    button {
      .icon {
        ${tw`text-base`}
      }
    }
  `,
]);
const filterStyle = {
  overlay: {
    position: 'fixed',
    opacity: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(34, 43, 69, 0.5)',
    zIndex: 10,
  },
  content: {
    height: '100%',
    width: '370px',
    position: 'absolute',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    right: 0,
    left: 'auto',
    bottom: 0,
    top: 0,
    borderRadius: '0px !important',
    padding: '0px !important',
  },
};
const userSummaryStyle = {
  overlay: {
    position: 'fixed',
    opacity: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(34, 43, 69, 0.5)',
    zIndex: 10,
  },
  content: {
    height: '100%',
    width:
      window.innerWidth > 1200
        ? '33%'
        : window.innerWidth > 900
        ? '40%'
        : '51%',
    position: 'absolute',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    right: 0,
    left: 'auto',
    bottom: 0,
    top: 0,
    borderRadius: '0px !important',
    padding: '0px !important',
  },
};
const modalStyle = {
  overlay: {
    position: 'fixed',
    opacity: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(34, 43, 69, 0.5)',
    zIndex: 10,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 0,
    padding: 0,
    width: '370px',
  },
};
const RoleContainer = styled.div(() => [tw`flex flex-row gap-1`]);
const AnchorSpan = styled.span(() => [
  tw`text-decoration[underline] cursor-pointer hover:text-info-500`,
]);
