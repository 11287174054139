/* eslint-disable no-underscore-dangle */
import {
  Store,
  applyMiddleware,
  createStore,
  compose,
  AnyAction,
  Dispatch,
  Middleware,
  CombinedState,
} from 'redux';
import { createRootReducer, rootSaga } from 'src/store/ducks';
import { IApplicationState } from 'src/store/ducks/types';
import { sagaMiddleware } from 'src/store/middlewares';

declare global {
  interface Window {
    /**
     * allows redux devtools to be used
     *
     * @type {*}
     * @memberof Window
     */
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

export function configureStore(
  initialState: IApplicationState,
): Store<IApplicationState> {
  const composeEnhancer: typeof compose =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  let middlewares: Middleware<object, unknown, Dispatch<AnyAction>>[] = [
    sagaMiddleware,
  ];

  const store: Store<CombinedState<IApplicationState>, any> & {
    dispatch: unknown;
  } = createStore(
    createRootReducer(),
    initialState,
    composeEnhancer(applyMiddleware(...middlewares)),
  );

  sagaMiddleware.run(rootSaga);

  return store;
}

const initialState = (window as any).initialReduxState;
const store: Store<IApplicationState, AnyAction> = configureStore(initialState);
export default store;
