import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import LoaderSvg from 'src/assets/images/loader.svg';
const LoaderContainer = styled.div(() => [
  tw`flex items-center justify-center w-full h-full absolute bg-gray-600 bg-opacity-60 top-0 z-40 overflow-y-hidden`,
]);
const Loader: React.FC = () => {
  return (
    <LoaderContainer>
      <img src={LoaderSvg} width='50' height='50' />
    </LoaderContainer>
  );
};

export default Loader;
