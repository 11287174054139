import React, { useEffect, useRef } from 'react';
import tw from 'twin.macro';
import {
  Icon,
  PageTitle,
  Button,
  Card,
  ConfirmModal,
} from 'roadrunner.uiframework';
import { useForm, SubmitHandler } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { IApplicationState } from 'src/store/ducks/types';
import { useDispatch, useSelector } from 'react-redux';
import history from 'src/common/history';
import FormTextInput from 'src/components/TextBox';
import FormSelectInput from 'src/components/SelectBox';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Dispatch } from 'redux';
import {
  clearRoleDetail,
  fetchCreateRole,
  fetchDeleteRoleDetail,
  fetchGetPermissionList,
  fetchGetRole,
  fetchGetRolePermissionList,
  fetchGetRoleTypeList,
  fetchUpdateRole,
} from 'src/store/ducks/role/action';
import { setHelmetIdentifier } from 'src/store/ducks/app/action';
import { useModal } from 'react-modal-hook';
import ReactModal from 'react-modal';

const schema = yup.object({
  name: yup.string().required(),
  type: yup.object().required(),
  permissions: yup.array().required(),
});

const RoleDetail: React.FC = () => {
  const dispatch: Dispatch = useDispatch();
  const deletedRowId: any = useRef();
  const { userid } = useParams<{ userid: string }>();
  const roleDetail = useSelector(
    (state: IApplicationState) => state.role.roleDetail,
  );
  const permissionList = useSelector(
    (state: IApplicationState) => state.role.permissionList,
  );
  const roleTypeList = useSelector(
    (state: IApplicationState) => state.role.roleTypeList,
  );
  const roleDetailPermissions = useSelector(
    (state: IApplicationState) => state.role.roleDetailPermission,
  );
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<{}> = (data: any) => {
    let createRoleObj = {
      name: data.name,
      permissions: data.permissions.map(item => item.value),
      type: data.type.value,
    };
    if (userid) {
      dispatch(fetchUpdateRole(createRoleObj, { id: userid }));
    } else {
      dispatch(fetchCreateRole(createRoleObj));
    }
  };

  useEffect(() => {
    if (userid) {
      dispatch(fetchGetRolePermissionList({ id: userid }));
      dispatch(fetchGetRole({ id: userid }));
    }
    dispatch(fetchGetPermissionList());
    dispatch(fetchGetRoleTypeList());
  }, []);

  useEffect(
    () => () => {
      dispatch(clearRoleDetail());
      dispatch(setHelmetIdentifier(''));
    },
    [],
  );
  useEffect(() => {
    if (userid) {
      reset({
        name: roleDetail.name,
        type: roleTypeList.find(item => item.value == String(roleDetail.type)),
        permissions: roleDetailPermissions.map(item => {
          return { value: item.id, label: item.description };
        }),
      });
      roleDetail.name && dispatch(setHelmetIdentifier(roleDetail.name));
    }
  }, [roleDetail, roleTypeList, roleDetailPermissions]);

  const deleteRole = () => {
    deletedRowId.current &&
      dispatch(fetchDeleteRoleDetail({ id: deletedRowId.current }));
  };
  const showConfirmationModal = (id: string) => {
    deletedRowId.current = id;
    showWarning();
  };
  //Delete butonuna basildiginda, cikan uyari modal
  const [showWarning, hideWarning] = useModal(() => (
    <ReactModal
      isOpen
      ariaHideApp={false}
      style={modalStyle}
      onRequestClose={hideWarning}
      shouldReturnFocusAfterClose={false}
    >
      <ConfirmModal
        title={'Kayıt Silme'}
        color='danger'
        icon='close'
        text={'Kaydı silmek istediğinize emin misiniz?'}
        onClose={hideWarning}
        primaryButtonText='Sil'
        primaryButtonElementType='secondary'
        primaryButtonElementColor='danger'
        onPrimaryButtonClick={() => {
          deleteRole();
          hideWarning();
        }}
        secondaryButtonText='Vazgeç'
        secondaryButtonElementType='primary'
        secondaryButtonElementColor='default'
        onSecondaryButtonClick={hideWarning}
      />
    </ReactModal>
  ));

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageTitleContainer>
          <PageTitle
            title={userid ? roleDetail.name : 'Yeni Rol Oluştur'}
            icon={
              <Link to='/Roller'>
                <Icon name='arrow-left' />
              </Link>
            }
          >
            <TitleButtonContainer>
              <Button
                color='default'
                icon=''
                label='Vazgeç'
                elementType='primary'
                onClick={e => {
                  e.preventDefault();
                  history.push(`/roller`);
                }}
              />
              <Button
                color='success'
                icon=''
                label='Rolü Kaydet'
                elementType='secondary'
                type='submit'
              />
            </TitleButtonContainer>
          </PageTitle>
        </PageTitleContainer>
        <NewRoleContainer>
          <FormRoleContainer>
            <Card>
              <SectionTitle>Genel Bilgiler</SectionTitle>
              <FormSideContainer>
                <FormTextInput
                  control={control}
                  name='name'
                  placeholder='Rol Adı'
                  size='large'
                  setValue={setValue}
                  required={errors.name}
                />
                <FormSelectInput
                  placeholder='Rol Tipi'
                  size='large'
                  name='type'
                  control={control}
                  options={roleTypeList}
                  required={errors.name}
                  hasDefaultValue={userid}
                />
              </FormSideContainer>
            </Card>
            <Card>
              <SectionTitle>Yetkiler</SectionTitle>
              <FormSideContainer>
                <FormSelectInput
                  placeholder='Yetki Seçiniz'
                  size='large'
                  type='multiple'
                  name='permissions'
                  control={control}
                  options={permissionList}
                />
              </FormSideContainer>
            </Card>
            {userid && (
              <DeleteUser>
                <Button
                  color='danger'
                  label='Bu kullanıcıyı sil'
                  elementType='secondary'
                  onClick={() => showConfirmationModal(roleDetail.roleId)}
                  type='button'
                />
              </DeleteUser>
            )}
          </FormRoleContainer>
        </NewRoleContainer>
      </form>
    </>
  );
};

export default RoleDetail;

const PageTitleContainer = styled.div(() => [tw`flex items-center mt-4 `]);
const TitleButtonContainer = styled.div(() => [
  css`
    ${tw`flex items-center gap-2`}
    button {
      .icon {
        ${tw`text-base`}
      }
    }
  `,
]);
const NewRoleContainer = styled.div(() => [
  tw`grid grid-cols-12 xl:padding[20px 135px] lg:padding[20px 65px]`,
]);
const SectionTitle = styled.span(() => [tw`text-sm text-grey-500`]);
const FormSideContainer = styled.div(() => [
  css`
    ${tw`flex flex-col gap-2 mt-3`}
    input {
      ${tw`w-full`}
    }
  `,
]);
const DeleteUser = styled.div(() => [tw`w-4/12`]);

const FormRoleContainer = styled.div(() => [
  tw`xl:col-span-12 lg:col-span-12 md:col-span-12 flex flex-col gap-3 ml-6`,
]);
const modalStyle = {
  overlay: {
    position: 'fixed',
    opacity: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(34, 43, 69, 0.5)',
    zIndex: 10,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 0,
    padding: 0,
    width: '370px',
  },
};
