import { UserManager, WebStorageStateStore, User } from 'oidc-client';

export default class AuthService {
  private userManager: UserManager;
  public Identity_Domain: string | undefined;
  public Vendor_Domain: string | undefined;
  public STS_BACKOFFICE_DOMAIN: string | undefined;
  constructor() {
    const Identity_Domain = process.env.REACT_APP__IDENTITY_DOMAIN;
    const Vendor_Domain = process.env.REACT_APP__VENDOR_DOMAIN;
    const STS_BACKOFFICE_DOMAIN = process.env.REACT_APP__STS_BACKOFFICE_DOMAIN;
    const settings: any = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: Identity_Domain,
      client_id: 'backofficejs',
      redirect_uri: STS_BACKOFFICE_DOMAIN + '/callback.html',
      automaticSilentRenew: true,
      silent_redirect_uri: STS_BACKOFFICE_DOMAIN + '/silent-renew.html',
      response_type: 'code',
      scope:
        'openid email profile vendorapi.manage membership.account.authenticated dockapi.manage membership.account.unauthenticated membership.manage',
      post_logout_redirect_uri: STS_BACKOFFICE_DOMAIN,
      filterProtocolClaims: true,
      loadUserInfo: true,
    };

    this.userManager = new UserManager(settings);
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect({ state: window.location.href });
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

  public getAccessToken(): Promise<string> {
    return this.userManager.getUser().then((data: any) => {
      return data.access_token;
    });
  }
}
