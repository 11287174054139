import React, { useEffect, useState, useCallback, useRef } from 'react';
import tw from 'twin.macro';
import styled, { css } from 'styled-components';
import {
  Button,
  PageTitle,
  Count,
  Icon,
  Labels,
  ConfirmModal,
} from 'roadrunner.uiframework';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
import { Dispatch } from 'redux';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { IApplicationState } from 'src/store/ducks/types';
import {
  fetchDeleteUser,
  fetchGetUserList,
  fetchGetUsersStatistic,
  setSelectedUserID,
} from 'src/store/ducks/user/action';
import history from 'src/common/history';
import UserFilter from './filter';
import moment from 'moment-timezone';
import parseParams from 'src/common/helpers/paramParser';
import setQueryParamstoUrl from 'src/common/helpers/setQueryParamstoUrl';
import UserSummary from './userSummary';
import Table from 'src/components/Table';
import setFilterToParams from 'src/common/helpers/setFilterToParams';
import modalCloser from 'src/common/helpers/modalCloser';

const KullaniciList: React.FC = (props: any) => {
  const userList = useSelector(
    (state: IApplicationState) => state.user.userList,
    shallowEqual,
  );
  const userListCount = useSelector(
    (state: IApplicationState) => state.user.userListTotalCount,
    shallowEqual,
  );
  const userStatistic = useSelector(
    (state: IApplicationState) => state.user.userStatistic,
    shallowEqual,
  );
  const accountId = useSelector(
    (state: IApplicationState) => state.account.userId,
  );
  const dispatch: Dispatch = useDispatch();
  const urlParams: any = new URLSearchParams(window.location.search);
  let searchParams = parseParams(decodeURIComponent(window.location.search));
  const deletedRowId: any = useRef();
  const [queryParam, setQueryParam] = useState({});
  let pageIndex = searchParams && searchParams.Take / 10 - 1;
  let initialSortBy =
    searchParams && searchParams.OrderBy
      ? [{ id: searchParams.OrderBy, desc: searchParams.IsDescending }]
      : [];
  const rolTableColumns = [
    {
      Header: 'Adı Soyadı',
      sticky: 'left',
      accessor: 'name',
      Cell: row => {
        return (
          <AnchorSpan
            onClick={() => {
              anchorSummary(row.cell.row.original.userId);
            }}
          >
            {row.cell.row.original.nameSurname}
          </AnchorSpan>
        );
      },
    },
    {
      Header: 'E-posta Adresi',
      accessor: 'email',
    },
    {
      Header: 'Telefon',
      accessor: 'phoneNumber',
    },
    {
      Header: 'Roller',
      disableSortBy: true,
      accessor: row => {
        return (
          <RoleContainer>
            {row.roles?.map(e => (
              <Labels label={e} />
            ))}
          </RoleContainer>
        );
      },
    },
    {
      Header: 'Statü',
      accessor: 'status',
      Cell: row => {
        let statusText = row.cell.row.original.status === 1 ? 'Aktif' : 'Pasif';
        return <span>{statusText}</span>;
      },
    },
    {
      Header: 'Son Islem Tarihi',
      accessor: 'createDate',
      Cell: row => {
        return (
          <span>
            {moment(row.cell.row.original.updateDate)
              .tz('Europe/Istanbul')
              .format('LLL')}
          </span>
        );
      },
    },
    {
      Header: <Icon className='color-grey-500 medium-24' name='arrow-right' />,
      id: 'action',
      width: 60,
      sticky: 'right',
      disableSortBy: true,
      accessor: row => {
        return (
          <>
            {/* {accountId !== row.userId && (
              <Button
                icon='delete'
                elementType='primary'
                onClick={() => showConfirmationModal(row.userId)}
              />
            )} */}
          </>
        );
      },
    },
  ];
  const newUser = () => {
    history.push('/kullanici');
  };
  const showConfirmationModal = (id: string) => {
    deletedRowId.current = id;
    showWarning();
  };

  const anchorSummary = userId => {
    dispatch(setSelectedUserID(userId));
    showUserDetail();
  };

  const deleteUser = () => {
    deletedRowId.current &&
      dispatch(fetchDeleteUser({ userid: deletedRowId.current }));
  };

  // Sayfa mount oldugunda urlden parametreleri cekip call atmasini sagliyor.
  useEffect(() => {
    dispatch(fetchGetUsersStatistic());
  }, []);

  useEffect(() => {
    getUsers(searchParams);
  }, [queryParam]);

  const getUsers = (params: any) => {
    dispatch(fetchGetUserList(params));
  };

  const getUserWithTableProps = tableProps => {
    let searchParams = parseParams(decodeURIComponent(window.location.search));
    if (tableProps) {
      let apiProps = {
        ...searchParams,
        Take: tableProps.pageIndex ? (tableProps.pageIndex + 1) * 10 : 10,
        Skip: tableProps.pageIndex ? tableProps.pageIndex * 10 : 0,
        OrderBy:
          tableProps.sortBy.length > 0 ? tableProps.sortBy[0].id : undefined,
        IsDescending:
          tableProps.sortBy.length > 0 ? tableProps.sortBy[0].desc : undefined,
      };
      setFilterToParams(apiProps);
      setQueryParam(apiProps);
    }
  };

  // Delete butonuna basildiginda, cikan uyari modal
  const [showWarning, hideWarning] = useModal(() => (
    <ReactModal
      isOpen
      ariaHideApp={false}
      style={modalStyle}
      onRequestClose={hideWarning}
      shouldReturnFocusAfterClose={false}
    >
      <ConfirmModal
        title={'Kayıt Silme'}
        color='danger'
        icon='close'
        text={'Kaydı silmek istediğinize emin misiniz?'}
        onClose={hideWarning}
        primaryButtonText='Sil'
        primaryButtonElementType='secondary'
        primaryButtonElementColor='danger'
        onPrimaryButtonClick={() => {
          deleteUser();
          hideWarning();
        }}
        secondaryButtonText='Vazgeç'
        secondaryButtonElementType='primary'
        secondaryButtonElementColor='default'
        onSecondaryButtonClick={hideWarning}
      />
    </ReactModal>
  ));
  //Listedeki filtreme modali
  const [showFilter, hideFilter] = useModal(e => (
    <ReactModal
      isOpen
      closeTimeoutMS={1000}
      // ariaHideApp={false}
      style={filterStyle}
      onRequestClose={() => modalCloser(hideFilter)}
    >
      <UserFilter
        onClose={() => modalCloser(hideFilter)}
        onSearch={setQueryParam}
      />
    </ReactModal>
  ));
  //Listedeki kullanicinin adina tiklandiginda acilan summary modal
  const [showUserDetail, hideUserDetail] = useModal(props => (
    <ReactModal
      isOpen
      ariaHideApp={false}
      style={userSummaryStyle}
      closeTimeoutMS={1000}
      onRequestClose={() => modalCloser(hideUserDetail)}
    >
      <UserSummary onClose={() => modalCloser(hideUserDetail)} />
    </ReactModal>
  ));

  return (
    <>
      <PageTitleContainer>
        <PageTitle
          title='Kullanıcılar'
          icon={
            <Link to='/'>
              <Icon name='arrow-left' />
            </Link>
          }
        >
          <TitleButtonContainer>
            <Button
              color='default'
              icon='filter'
              label='Filtrele'
              elementType='primary'
              onClick={showFilter}
            />
            <Button
              color='success'
              icon=''
              label='Yeni Kullanıcı'
              elementType='secondary'
              onClick={() => newUser()}
            />
          </TitleButtonContainer>
        </PageTitle>
      </PageTitleContainer>
      <CounterContainer>
        <Count
          color='info'
          count={userStatistic.totalCount}
          icon='chevron-right'
          subText='Toplam Kullanıcılar'
          type='secondary'
          onClick={() => {
            history.push('/kullanicilar');
          }}
        />
        <Count
          color='success'
          count={userStatistic.activeCount}
          icon='chevron-right'
          subText='Aktif Kullanıcılar'
          type='secondary'
          onClick={() => {
            urlParams.set('status', '1');
            setQueryParam(urlParams);
            setQueryParamstoUrl(urlParams);
          }}
        />
        <Count
          color='warning'
          count={userStatistic.passiveCount}
          icon='chevron-right'
          subText='Pasif Kullanıcılar'
          type='secondary'
          onClick={() => {
            urlParams.set('status', '0');
            setQueryParam(urlParams);
            setQueryParamstoUrl(urlParams);
          }}
        />
      </CounterContainer>
      <Table
        columns={rolTableColumns}
        data={userList}
        manualPagination={true}
        manualSort={true}
        fetchData={props => getUserWithTableProps(props)}
        totalRowCount={userListCount}
        initialPageIndex={pageIndex}
        initialSortBy={initialSortBy}
      />
    </>
  );
};

export default KullaniciList;

const PageTitleContainer = styled.div(() => [
  tw`flex items-center box-shadow[0px 2px 12px rgba(0, 0, 0, 0.03)] mt-4`,
]);
const TitleButtonContainer = styled.div(() => [
  css`
    ${tw`flex items-center gap-2`}
    button {
      .icon {
        ${tw`text-base`}
      }
    }
  `,
]);
const CounterContainer = styled.div(() => [
  tw`flex items-center justify-between`,
]);
const filterStyle = {
  overlay: {
    position: 'fixed',
    opacity: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(34, 43, 69, 0.5)',
    zIndex: 10,
  },
  content: {
    height: '100%',
    width: '370px',
    position: 'absolute',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    right: 0,
    left: 'auto',
    bottom: 0,
    top: 0,
    borderRadius: '0px !important',
    padding: '0px !important',
  },
};
const userSummaryStyle = {
  overlay: {
    position: 'fixed',
    opacity: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(34, 43, 69, 0.5)',
    zIndex: 10,
  },
  content: {
    height: '100%',
    width:
      window.innerWidth > 1200
        ? '33%'
        : window.innerWidth > 900
        ? '40%'
        : '51%',
    position: 'absolute',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    right: 0,
    left: 'auto',
    bottom: 0,
    top: 0,
    borderRadius: '0px !important',
    padding: '0px !important',
  },
};
const modalStyle = {
  overlay: {
    position: 'fixed',
    opacity: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(34, 43, 69, 0.5)',
    zIndex: 10,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 0,
    padding: 0,
    width: '370px',
  },
};
const RoleContainer = styled.div(() => [tw`flex flex-row gap-1`]);
const AnchorSpan = styled.span(() => [
  tw`text-decoration[underline] cursor-pointer hover:text-info-500`,
]);
