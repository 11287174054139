import React from 'react';
import { Controller } from 'react-hook-form';
import { SelectBox } from 'roadrunner.uiframework';

export default function FormSelectInput(props) {
  const {
    placeholder,
    size = 'small',
    type,
    disabled,
    name,
    control,
    options,
    dropdownIndicator,
    ...rest
  } = props;
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <SelectBox
            placeholder={placeholder}
            size={size}
            type={type}
            disabled={disabled}
            options={options}
            dropdownIndicator={dropdownIndicator}
            {...field}
            {...rest}
          />
        )}
      />
    </>
  );
}
