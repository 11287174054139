import React from 'react';
import { Link } from 'react-router-dom';

export default [
  {
    link: <a href='/'>Home</a>,
  },
  {
    link: 'Kullanıcılar',
    child: [
      {
        link: <Link to='/kullanicilar'>Kullanıcı Listesi</Link>,
      },
      {
        link: <Link to='/kullanici'>Kullanıcı Ekle</Link>,
      },
      {
        link: <Link to='/roller'>Rol Listesi</Link>,
      },
    ],
  },
  {
    link: 'Ayarlar',
    child: [
      {
        link: <Link to='/hizmetler'>Hizmet Yönetimi</Link>,
      },
    ],
  },
];
