import { AnyRoleAction } from './action';
import { ROLE_ACTION_TYPES } from './constants';
import { IRoleState } from './types';

export const initialState: IRoleState = {
  roleList: [],
  totalCount: 0,
  filteredCount: 0,
  roleDetail: {},
  permissionList: [],
  roleTypeList: [],
  roleDetailPermission: [],
};

export const roleReducer = (
  state: IRoleState = initialState,
  action: AnyRoleAction,
): IRoleState => {
  switch (action.type) {
    case ROLE_ACTION_TYPES.SET_ROLE_LIST:
      return {
        ...state,
        roleList: action.payload.data,
        totalCount: action.payload.totalCount,
        filteredCount: action.payload.filteredCount,
      };
    case ROLE_ACTION_TYPES.SET_PERMISSION_LIST:
      return {
        ...state,
        permissionList: action.payload,
      };
    case ROLE_ACTION_TYPES.SET_ROLE_TYPES:
      return {
        ...state,
        roleTypeList: action.payload,
      };
    case ROLE_ACTION_TYPES.SET_ROLE_DETAIL:
      return {
        ...state,
        roleDetail: action.payload,
      };
    case ROLE_ACTION_TYPES.SET_ROLE_PERMISSION_LIST:
      return {
        ...state,
        roleDetailPermission: action.payload,
      };
    case ROLE_ACTION_TYPES.CLEAR_ROLE_DETAIL:
      return {
        ...state,
        roleDetail: {},
        roleDetailPermission: [],
      };
    default:
      return {
        ...state,
      };
  }
};
