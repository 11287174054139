import { action } from 'typesafe-actions';
import { VENDOR_ACTION_TYPES } from './constants';
import { IGetVendorListReqModel } from './types';

export const fetchGetVendorList = (payload: IGetVendorListReqModel) =>
  action(VENDOR_ACTION_TYPES.GET_VENDOR_LIST, payload);
export const fetchSetVendorList = (payload: any) =>
  action(VENDOR_ACTION_TYPES.SET_VENDOR_LIST, payload);

export type AnyVendorAction =
  | ReturnType<typeof fetchGetVendorList>
  | ReturnType<typeof fetchSetVendorList>;
