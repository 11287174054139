import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import appConfigs from 'src/configs/app';
import {
  IRequestInterceptorProps,
  IResponseInterceptor,
} from 'src/httpClient/interceptors/types';
import {
  requestInterceptor,
  responseInterceptor,
} from 'src/httpClient/interceptors';

interface IHttpClient extends AxiosInstance {}

export const createHttpClient = (
  configs: AxiosRequestConfig = {},
  reqInterceptor?: IRequestInterceptorProps,
  resInterceptor?: IResponseInterceptor,
) => {
  const HttpClient: IHttpClient = axios.create(configs);
  if (requestInterceptor) {
    HttpClient.interceptors.request.use(
      reqInterceptor?.onFulfilled,
      reqInterceptor?.onRejected,
    );
  }
  if (responseInterceptor) {
    HttpClient.interceptors.response.use(
      resInterceptor?.onFulfilled,
      resInterceptor?.onRejected,
    );
  }

  return HttpClient;
};

/**
 ** === HTTP CLIENTS
 * =================================================================================
 */

export const defaultHttpClient: AxiosInstance = createHttpClient(
  {
    baseURL: appConfigs.api.baseURL,
  },
  requestInterceptor,
  responseInterceptor,
);

export default defaultHttpClient;
