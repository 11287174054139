import { AxiosError, AxiosResponse } from 'axios';
import {
  ICourierState,
  IGetCourierListReqModel,
} from 'src/store/ducks/courier/types';
import { getCourierListFetch } from '../endpoints/courier';

export const getCourierListFetchResolver: (
  params: IGetCourierListReqModel,
) => Promise<AxiosResponse<ICourierState>> = (
  params: IGetCourierListReqModel,
) => {
  return getCourierListFetch(params)
    .then((res: AxiosResponse<ICourierState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
