import React, { useEffect, useRef, useState } from 'react';
import tw from 'twin.macro';
import styled, { css } from 'styled-components';
import {
  Button,
  PageTitle,
  Card,
  TabMenu,
  SeeAll,
  Icon,
  ConfirmModal,
} from 'roadrunner.uiframework';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from 'src/store/ducks/types';
import {
  clearUserDetail,
  fetchDeleteUser,
  fetchGetUser,
  fetchGetUserList,
  fetchGetUsersStatistic,
  fetchSetUser,
  fetchUpdateUser,
  sendUserEmailConfirmation,
  sendUserSmsConfirmation,
} from 'src/store/ducks/user/action';
import FormTextInput from 'src/components/TextBox';
import { Link } from 'react-router-dom';
import { fetchGetRoleListRequest } from 'src/store/ducks/role/action';
import { useForm, SubmitHandler } from 'react-hook-form';
import FormSelectInput from 'src/components/SelectBox';
import history from 'src/common/history';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { setHelmetIdentifier } from 'src/store/ducks/app/action';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
interface UserConfirmationLabelProps {
  isConfirmed?: boolean;
}
const statuType = [
  {
    value: 1,
    label: 'Aktif',
  },
  {
    value: 0,
    label: 'Pasif',
  },
];

const schema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phoneNumber: yup.string(),
  email: yup.string(),
  roleIds: yup.array().required(),
  status: yup.object().required(),
});

const KullaniciDetail: React.FC = () => {
  const dispatch: Dispatch = useDispatch();
  const [roleList, setroleList] = useState([]);
  const userState = useSelector((state: IApplicationState) => state.user);
  const roleState = useSelector((state: IApplicationState) => state.role);
  const { userid } = useParams<{ userid: string }>();
  const deletedRowId: any = useRef();
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  //Delete confirmation modal
  const [showWarning, hideWarning] = useModal(() => (
    <ReactModal
      isOpen
      ariaHideApp={false}
      style={modalStyle}
      onRequestClose={hideWarning}
      shouldReturnFocusAfterClose={false}
    >
      <ConfirmModal
        title={'Kayıt Silme'}
        color='danger'
        icon='close'
        text={'Kaydı silmek istediğinize emin misiniz?'}
        onClose={hideWarning}
        primaryButtonText='Sil'
        primaryButtonElementType='secondary'
        primaryButtonElementColor='danger'
        onPrimaryButtonClick={() => {
          deleteUser();
          hideWarning();
        }}
        secondaryButtonText='Vazgeç'
        secondaryButtonElementType='primary'
        secondaryButtonElementColor='default'
        onSecondaryButtonClick={hideWarning}
      />
    </ReactModal>
  ));

  const onSubmit: SubmitHandler<any> = data => {
    if (userid) {
      dispatch(
        fetchUpdateUser(
          {
            ...data,
            roleIds: data.roleIds?.map(item => item.value),
            status: data.status.value,
            phoneNumberConfirmed: userState.userDetail?.phoneNumberConfirmed,
            emailConfirmed: userState.userDetail?.emailConfirmed,
          },
          { userid },
        ),
      );
    } else {
      dispatch(
        fetchSetUser({
          ...data,
          roleIds: data.roleIds?.map(item => item.value),
          status: data.status.value,
          phoneNumberConfirmed: false,
          emailConfirmed: false,
          type: 2,
        }),
      );
    }
  };

  const sendEmailConfirmation = () => {
    userState.userDetail?.userId &&
      dispatch(
        sendUserEmailConfirmation({ userid: userState.userDetail?.userId }),
      );
  };
  const sendSmsConfirmation = () => {
    userState.userDetail?.userId &&
      dispatch(
        sendUserSmsConfirmation({ userid: userState.userDetail?.userId }),
      );
  };
  const deleteUser = () => {
    deletedRowId.current &&
      dispatch(fetchDeleteUser({ userid: deletedRowId.current }));
  };
  const showConfirmationModal = (id: string | any) => {
    deletedRowId.current = id;
    showWarning();
  };

  useEffect(() => {
    reset({
      firstName: userState.userDetail?.firstName,
      lastName: userState.userDetail?.lastName,
      email: userState.userDetail?.email,
      phoneNumber: userState.userDetail?.phoneNumber,
      roleIds: userState.userDetail?.roleIds?.map((item: any) => {
        return { value: item.roleId, label: item.name };
      }),
      status: {
        value: userState.userDetail?.status,
        label: userState.userDetail?.status === 1 ? 'Aktif' : 'Pasif',
      },
    });
    userState.userDetail?.firstName &&
      dispatch(
        setHelmetIdentifier(
          userState.userDetail?.firstName +
            ' ' +
            userState.userDetail?.lastName,
        ),
      );
  }, [userState.userDetail]);

  useEffect(() => {
    dispatch(fetchGetUsersStatistic());
    dispatch(
      fetchGetUserList({ Take: 5, IsDescending: false, OrderBy: 'createDate' }),
    );
    dispatch(fetchGetRoleListRequest({}));
    if (userid) {
      dispatch(fetchGetUser({ userid }));
    } else {
      reset({
        status: { value: 1, label: 'Aktif' },
      });
    }
  }, []);

  useEffect(() => {
    let tempArray: any = [];
    roleState?.roleList?.map(item => {
      tempArray.push({
        value: item.roleId,
        label: item.name,
      });
    });
    setroleList(tempArray);
  }, [roleState.roleList]);

  useEffect(
    () => () => {
      dispatch(clearUserDetail());
      dispatch(setHelmetIdentifier(''));
    },
    [],
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageTitleContainer>
          <PageTitle
            title={
              userid
                ? userState.userDetail?.firstName +
                  ' ' +
                  userState.userDetail?.lastName
                : 'Yeni Kullanıcı'
            }
            icon={
              <Link to='/kullanicilar'>
                <Icon name='arrow-left' />
              </Link>
            }
          >
            <TitleButtonContainer>
              <Button
                color='default'
                icon=''
                label='Vazgeç'
                elementType='primary'
                onClick={e => {
                  e.preventDefault();
                  history.push(`/kullanicilar`);
                }}
              />
              <Button
                color='success'
                icon=''
                label='Kullanıcıyı Kaydet'
                elementType='secondary'
              />
            </TitleButtonContainer>
          </PageTitle>
        </PageTitleContainer>
        <NewUserContainer>
          <LastAddedList>
            <Card>
              <SectionTitle>Son Eklenenler</SectionTitle>
              <LastUsersContainer>
                {userState.userList?.map((item, index) => (
                  <>
                    <TabMenu
                      key={index}
                      icon='chevron-right'
                      subText={item.email || ''}
                      text={item.nameSurname || ''}
                      onClick={() => {
                        history.push(`/kullanici/${item.userId}`);
                      }}
                    />
                  </>
                ))}
              </LastUsersContainer>
              <SeeAll
                seeAll={
                  <Link to='/kullanicilar'>Tüm Kullanıcı Listesine Git</Link>
                }
              />
            </Card>
          </LastAddedList>
          <FormUserContainer>
            <Card>
              <SectionTitle>Kisisel Bilgiler</SectionTitle>
              <FormSideContainer>
                <FormTextInput
                  control={control}
                  name='firstName'
                  placeholder='Adı'
                  size='large'
                  setValue={setValue}
                  required={errors.firstName}
                />
                <FormTextInput
                  control={control}
                  name='lastName'
                  placeholder='Soyadı'
                  size='large'
                  required={errors.lastName}
                />
                <FormTextInput
                  control={control}
                  name='email'
                  placeholder='E-Posta Adresi'
                  size='large'
                  required={errors.email}
                />
                <FormTextInput
                  control={control}
                  name='phoneNumber'
                  placeholder='Telefon Numarası'
                  size='large'
                  required={errors.phoneNumber}
                />
              </FormSideContainer>
            </Card>
            <Card>
              <SectionTitle>Roller</SectionTitle>
              <FormSideContainer>
                <FormSelectInput
                  placeholder='Yetki Seciniz'
                  size='large'
                  type='multiple'
                  name='roleIds'
                  options={roleList}
                  control={control}
                />
              </FormSideContainer>
            </Card>
            <Card>
              <SectionTitle>Statü</SectionTitle>
              <FormSideContainer>
                <FormSelectInput
                  placeholder='Statu seciniz'
                  size='large'
                  name='status'
                  control={control}
                  options={statuType}
                  hasDefaultValue={true}
                />
                <StatuSubText>
                  Pasif durumunda kullanıcı hesabını silmez sadece aktif
                  edilinceye kadar sisteme giriş yapamaz
                </StatuSubText>
              </FormSideContainer>
            </Card>
            {userid && (
              <Card>
                <SectionTitle>Aktivasyon Durumu</SectionTitle>
                <ActivationContainer>
                  <ActivationInformation>
                    <UserConfirmationLabel
                      isConfirmed={userState.userDetail?.emailConfirmed}
                    >
                      {userState.userDetail?.emailConfirmed
                        ? 'E-mail doğrulama yapıldı'
                        : 'E-mail doğrulama henuz yapılmadı'}
                    </UserConfirmationLabel>
                    <UserConfirmationLabel
                      isConfirmed={userState.userDetail?.phoneNumberConfirmed}
                    >
                      {userState.userDetail?.phoneNumberConfirmed
                        ? 'SMS doğrulama yapıldı'
                        : 'SMS doğrulama henuz yapılmadı'}
                    </UserConfirmationLabel>
                  </ActivationInformation>
                  <SendAgainButtons>
                    {!userState.userDetail?.emailConfirmed && (
                      <Button
                        color='default'
                        label='Tekrar Mail Doğrulama Gönder'
                        elementType='primary'
                        onClick={sendEmailConfirmation}
                      />
                    )}
                    {!userState.userDetail?.phoneNumberConfirmed && (
                      <Button
                        color='default'
                        label='Tekrar SMS Doğrulama Gönder'
                        elementType='primary'
                        onClick={sendSmsConfirmation}
                      />
                    )}
                  </SendAgainButtons>
                </ActivationContainer>
              </Card>
            )}
            {userid && (
              <DeleteUser>
                <Button
                  color='danger'
                  label='Bu kullanıcıyı sil'
                  elementType='secondary'
                  onClick={() =>
                    showConfirmationModal(userState.userDetail?.userId)
                  }
                  type='button'
                />
              </DeleteUser>
            )}
          </FormUserContainer>
        </NewUserContainer>
      </form>
    </>
  );
};

export default KullaniciDetail;

const PageTitleContainer = styled.div(() => [tw`flex items-center mt-4 `]);
const TitleButtonContainer = styled.div(() => [
  css`
    ${tw`flex items-center gap-2`}
    button {
      .icon {
        ${tw`text-base`}
      }
    }
  `,
]);
const NewUserContainer = styled.div(() => [
  tw`grid grid-cols-12 xl:padding[20px 135px] sm:padding[20px 65px]`,
]);
const SectionTitle = styled.span(() => [tw`text-sm text-grey-500`]);
const LastUsersContainer = styled.div(() => [
  css`
    ${tw`flex flex-col gap-3 mt-2`}
    button {
      ${tw`w-full`}
    }
  `,
]);
const FormSideContainer = styled.div(() => [
  css`
    ${tw`flex flex-col gap-2 mt-3`}
    input {
      ${tw`w-full`}
    }
  `,
]);
const LastAddedList = styled.div(() => [
  tw`xl:col-span-4 xl:block md:hidden sm:hidden`,
]);
const FormUserContainer = styled.div(() => [
  tw`xl:col-span-8 lg:col-span-12 md:col-span-12 sm:col-span-12 flex flex-col gap-3 ml-6`,
]);
const StatuSubText = styled.span(() => [tw`w-9/12 mt-2 text-grey-400`]);
const ContactConfirmSubText = styled.span(props => [
  tw`w-9/12 mt-2 text-grey-400`,
  props.color === 'warning' ? tw`text-red-500` : tw`text-success-600`,
]);
const ActivationInformation = styled.div(() => [tw`flex flex-col`]);
const ActivationContainer = styled.div(() => [
  tw`flex flex-row items-start justify-between`,
]);
const UserConfirmationLabel = styled.div(
  (props: UserConfirmationLabelProps) => [
    tw`border-radius[100px] font-medium text-xs padding[4px 10px] w-auto mt-2`,
    props.isConfirmed
      ? tw`bg-success-100 text-success-500`
      : tw`bg-danger-100 text-danger-500`,
  ],
);
const SendAgainButtons = styled.div(() => [
  tw`flex flex-row gap-2 mt-2 w-auto`,
]);
const DeleteUser = styled.div(() => [tw`w-4/12`]);

const modalStyle = {
  overlay: {
    position: 'fixed',
    opacity: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(34, 43, 69, 0.5)',
    zIndex: 10,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 0,
    padding: 0,
    width: '370px',
  },
};
