import React, { useEffect, useRef } from 'react';
import {
  useTable,
  usePagination,
  useSortBy,
  useResizeColumns,
  useFlexLayout,
} from 'react-table';
import { useSticky } from 'react-table-sticky';

import { Icon } from 'roadrunner.uiframework';
import Pagination from './components/pagination';
import './style.scss';

function Table({
  columns,
  data,
  totalRowCount,
  manualSort,
  fetchData,
  manualPagination = false,
  initialPageIndex = 0,
  initialSortBy = [],
}) {
  const sortObj = useRef([]);
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: initialPageIndex, sortBy: initialSortBy },
      manualPagination: manualPagination,
      manualSortBy: manualSort,
      pageCount: Math.ceil(totalRowCount / 10),
      autoResetRowState: false,
      autoResetPage: false,
    },
    useSortBy,
    usePagination,
    useResizeColumns,
    useFlexLayout,
    useSticky,
  );

  useEffect(() => {
    fetchData && fetchData({ pageIndex, sortBy });
  }, [pageIndex, sortBy]);

  return (
    <div className='rdui-table'>
      <div className='rdui-table-container'>
        <div className='table sticky' {...getTableProps()}>
          <div className='header'>
            {headerGroups.map(headerGroup => (
              <div {...headerGroup.getHeaderGroupProps()} className='tr'>
                {headerGroup.headers.map(column => (
                  <div
                    {...column.getHeaderProps()}
                    className='th'
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                        style: column.style,
                      },
                      column.getHeaderProps(column),
                      column.getSortByToggleProps(column),
                    ])}
                  >
                    <div className='rdui-table-header'>
                      <span className='title'>{column.render('Header')}</span>

                      <div
                        {...column.getResizerProps()}
                        className={`resizer ${
                          column.isResizing ? 'isResizing' : ''
                        }`}
                      />
                      <div>
                        {column.canSort && (
                          <span className='sort'>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <Icon name='chevron-down' />
                              ) : (
                                <Icon name='chevron-up' />
                              )
                            ) : (
                              <Icon name='sort' />
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className='body'>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className='tr'>
                  {row.cells.map(cell => {
                    return (
                      <div {...cell.getCellProps()} className='td'>
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className='pagination'>
        <div className='current-stats'>
          Şu anda {pageIndex * pageSize} - {(pageIndex + 1) * pageSize} arası
          kayıt gösteriliyor
        </div>

        <div className='pages'>
          {totalRowCount > 10 && (
            <ul>
              <li className='prev'>
                <button onClick={previousPage} disabled={!canPreviousPage}>
                  <Icon name='chevron-left' />
                </button>
              </li>
              <Pagination
                onPageChange={gotoPage}
                totalCount={totalRowCount}
                currentPage={pageIndex}
                pageSize={pageSize}
              />
              <li className='next'>
                <button onClick={nextPage} disabled={!canNextPage}>
                  <Icon name='chevron-right' />
                </button>
              </li>
            </ul>
          )}
        </div>

        <div className='total-stats'>Toplam Kayıt : {totalRowCount}</div>
      </div>
    </div>
  );
}

export default Table;
