import React, { useEffect } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';

const Container = styled.div(() => [tw`flex w-full m-6 text-5xl`]);

const Home: React.FC = () => {
  return <Container></Container>;
};

export default Home;
