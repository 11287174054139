import { ROLE_ACTION_TYPES } from 'src/store/ducks/role/constants';
import { action } from 'typesafe-actions';
import {
  IGetRoleReqModel,
  IRoleListRawModel,
  IPermissionListRawModel,
  ICreateRoleReqModel,
  IGetRoleDetailReqModel,
  IRole,
  IGetRolePermissionReqModel,
  IPermission,
  IDeleteRoleReqModel,
} from './types';

export const fetchGetRoleListRequest = (payload: IGetRoleReqModel) =>
  action(ROLE_ACTION_TYPES.GET_ROLE_LIST, payload);
export const fetchSetRoleList = (payload: IRoleListRawModel) =>
  action(ROLE_ACTION_TYPES.SET_ROLE_LIST, payload);
export const fetchGetPermissionList = () =>
  action(ROLE_ACTION_TYPES.GET_PERMISSION_LIST);
export const fetchSetPermissionList = (payload: IPermissionListRawModel) =>
  action(ROLE_ACTION_TYPES.SET_PERMISSION_LIST, payload);
export const fetchGetRoleTypeList = () =>
  action(ROLE_ACTION_TYPES.GET_ROLE_TYPES);
export const fetchSetRoleTypeList = (payload: any) =>
  action(ROLE_ACTION_TYPES.SET_ROLE_TYPES, payload);
export const fetchCreateRole = (payload: ICreateRoleReqModel) =>
  action(ROLE_ACTION_TYPES.CREATE_ROLE, payload);
export const fetchGetRole = (payload: IGetRoleDetailReqModel) =>
  action(ROLE_ACTION_TYPES.GET_ROLE, payload);
export const fetchSetRoleDetail = (payload: IRole) =>
  action(ROLE_ACTION_TYPES.SET_ROLE_DETAIL, payload);
export const clearRoleDetail = () =>
  action(ROLE_ACTION_TYPES.CLEAR_ROLE_DETAIL);
export const fetchGetRolePermissionList = (
  payload: IGetRolePermissionReqModel,
) => action(ROLE_ACTION_TYPES.GET_ROLE_PERMISSION_LIST, payload);
export const fetchSetRolePermissionList = (payload: IPermission[]) =>
  action(ROLE_ACTION_TYPES.SET_ROLE_PERMISSION_LIST, payload);
export const fetchUpdateRole = (payload: ICreateRoleReqModel, params: any) =>
  action(ROLE_ACTION_TYPES.UPDATE_ROLE, payload, params);
export const fetchDeleteRoleDetail = (payload: IDeleteRoleReqModel) =>
  action(ROLE_ACTION_TYPES.DELETE_ROLE, payload);

export type AnyRoleAction =
  | ReturnType<typeof fetchGetRoleListRequest>
  | ReturnType<typeof fetchGetPermissionList>
  | ReturnType<typeof fetchSetPermissionList>
  | ReturnType<typeof fetchGetRoleTypeList>
  | ReturnType<typeof fetchSetRoleTypeList>
  | ReturnType<typeof fetchCreateRole>
  | ReturnType<typeof fetchUpdateRole>
  | ReturnType<typeof fetchGetRole>
  | ReturnType<typeof fetchSetRoleDetail>
  | ReturnType<typeof fetchGetRolePermissionList>
  | ReturnType<typeof fetchSetRolePermissionList>
  | ReturnType<typeof fetchDeleteRoleDetail>
  | ReturnType<typeof clearRoleDetail>
  | ReturnType<typeof fetchSetRoleList>;
