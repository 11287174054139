import { AxiosError, AxiosResponse } from 'axios';
import {
  IGetServiceListReqModel,
  IServiceDetailReqModel,
  IServiceRawModel,
  IServiceState,
} from 'src/store/ducks/service/types';

import {} from 'src/store/ducks/user/types';
import {
  createServiceFetch,
  deleteServiceFetch,
  getServiceCollectingTypeFetch,
  getServiceDeliveryTimeFetch,
  getServiceDetailFetch,
  getServiceListFetch,
  getServiceTransferTypeFetch,
  updateServiceFetch,
} from '../endpoints/service';

export const getServiceListFetchResolver: (
  params: IGetServiceListReqModel,
) => Promise<AxiosResponse<IServiceState>> = (
  params: IGetServiceListReqModel,
) => {
  return getServiceListFetch(params)
    .then((res: AxiosResponse<IServiceState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const getServiceDetailFetchResolver: (
  params: IServiceDetailReqModel,
) => Promise<AxiosResponse<IServiceState>> = (
  params: IServiceDetailReqModel,
) => {
  return getServiceDetailFetch(params)
    .then((res: AxiosResponse<IServiceState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const getServiceDeliveryTimeFetchResolver: () => Promise<
  AxiosResponse<IServiceState>
> = () => {
  return getServiceDeliveryTimeFetch()
    .then((res: AxiosResponse<IServiceState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const getServiceCollectingTypeFetchResolver: () => Promise<
  AxiosResponse<IServiceState>
> = () => {
  return getServiceCollectingTypeFetch()
    .then((res: AxiosResponse<IServiceState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const getServiceTransferTypeFetchResolver: () => Promise<
  AxiosResponse<IServiceState>
> = () => {
  return getServiceTransferTypeFetch()
    .then((res: AxiosResponse<IServiceState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const updateServiceFetchResolver: (
  payload: IServiceRawModel,
  params: any,
) => Promise<AxiosResponse<any>> = (payload: IServiceRawModel, params: any) => {
  return updateServiceFetch(payload, params)
    .then((res: AxiosResponse<any>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const createServiceFetchResolver: (
  payload: IServiceRawModel,
) => Promise<AxiosResponse<any>> = (payload: IServiceRawModel) => {
  return createServiceFetch(payload)
    .then((res: AxiosResponse<any>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const deleteServiceFetchResolver: (
  payload: IServiceDetailReqModel,
) => Promise<AxiosResponse<any>> = (payload: IServiceDetailReqModel) => {
  return deleteServiceFetch(payload)
    .then((res: AxiosResponse<any>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
