import React, { useEffect } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from 'src/store/ducks/types';
import history from 'src/common/history';
import ItemSummary from 'src/components/ItemSummary';
import { UserSubSection, Button, Labels } from 'roadrunner.uiframework';
import {
  clearUserDetail,
  fetchGetUser,
  getUserActivities,
  sendUserEmailConfirmation,
  sendUserSmsConfirmation,
  setUserStatus,
} from 'src/store/ducks/user/action';
import { ISetUserActivitiesModel } from 'src/store/ducks/user/types';
import moment from 'moment-timezone';

type IUserSummary = {
  onClose?: Function;
};
interface UserConfirmationLabelProps {
  isConfirmed?: boolean;
}

const UserSummary: React.FC<IUserSummary> = (props: IUserSummary) => {
  const userState = useSelector((state: IApplicationState) => state.user);
  const isImpersonating = useSelector(
    (state: IApplicationState) => state.account.isImpersonating,
  );

  const { onClose } = props;
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    getUserDetail();
  }, [userState.selectedUserId]);

  const setUserStatusOpposite = () => {
    if (
      userState.selectedUserId &&
      userState.userDetail?.status !== undefined
    ) {
      let nextStatus = userState.userDetail?.status ? 0 : 1;
      dispatch(
        setUserStatus({
          userid: userState.selectedUserId,
          statusId: nextStatus,
        }),
      );
    }
  };

  useEffect(
    () => () => {
      dispatch(clearUserDetail());
    },
    [],
  );

  const getUserDetail = () => {
    if (userState.selectedUserId) {
      dispatch(fetchGetUser({ userid: userState.selectedUserId }));
      dispatch(getUserActivities({ userid: userState.selectedUserId }));
    }
  };
  const sendEmailConfirmation = () => {
    userState.selectedUserId &&
      dispatch(sendUserEmailConfirmation({ userid: userState.selectedUserId }));
  };
  const sendSmsConfirmation = () => {
    userState.selectedUserId &&
      dispatch(sendUserSmsConfirmation({ userid: userState.selectedUserId }));
  };
  const impersonate = userId => {
    let currentHref = window.location.href;
    localStorage.clear();
    window.location.href = `https://identity.test.scotty.com.tr/Account/ImpersonateUser?UserId=${userId}&returnUrl=${currentHref}`;
  };
  const loginForUser = (
    <Button
      color='default'
      label='Kullanıcı adına login ol'
      elementType='primary'
      onClick={() => impersonate(userState.selectedUserId)}
    />
  );
  return (
    <>
      <ItemSummary
        onClose={onClose}
        title={
          <UserSubSection
            name={
              userState.userDetail?.firstName +
              ' ' +
              userState.userDetail?.lastName
            }
            description={
              userState.userDetail?.email
                ? userState.userDetail?.email
                : userState.userDetail?.phoneNumber
            }
          />
        }
        tableSide={
          <UserInformationTable>
            <UserInformationRow>
              <InformationRowLeft>Adı</InformationRowLeft>
              <InformationRowRight>
                {userState.userDetail?.firstName}
              </InformationRowRight>
            </UserInformationRow>
            <UserInformationEvenRow>
              <InformationRowLeft>Soyadı</InformationRowLeft>
              <InformationRowRight>
                {userState.userDetail?.lastName}
              </InformationRowRight>
            </UserInformationEvenRow>
            <UserInformationRow>
              <InformationRowLeft>E-posta Adresi</InformationRowLeft>
              <InformationRowRight>
                {userState.userDetail?.email}
              </InformationRowRight>
            </UserInformationRow>
            <UserInformationEvenRow>
              <InformationRowLeft>Statu</InformationRowLeft>
              <InformationRowRight>
                {userState.userDetail?.status === 1 ? 'Aktif' : 'Pasif'}
              </InformationRowRight>
            </UserInformationEvenRow>
            <UserInformationRow>
              <InformationRowLeft>Roller</InformationRowLeft>
              <InformationRowRight>
                {userState.userDetail?.roleIds?.map((item: any) => (
                  <Labels label={item.name} />
                ))}
              </InformationRowRight>
            </UserInformationRow>
          </UserInformationTable>
        }
        buttonSide={
          <ButtonSideContainer>
            <ConfirmationLabelSideContainer>
              <UserConfirmationLabel
                isConfirmed={userState.userDetail?.emailConfirmed}
              >
                {userState.userDetail?.emailConfirmed
                  ? 'E-mail dogrulama yapıldı'
                  : 'E-mail dogrulama yapılmadı'}
              </UserConfirmationLabel>
              <UserConfirmationLabel
                isConfirmed={userState.userDetail?.phoneNumberConfirmed}
              >
                {userState.userDetail?.phoneNumberConfirmed
                  ? 'SMS dogrulama yapıldı'
                  : 'SMS dogrulama yapılmadı'}
              </UserConfirmationLabel>
            </ConfirmationLabelSideContainer>
            <SendAgainButtons>
              {!userState.userDetail?.emailConfirmed && (
                <Button
                  color='default'
                  label='Tekrar Mail Doğrulama Gönder'
                  elementType='primary'
                  onClick={sendEmailConfirmation}
                />
              )}
              {!userState.userDetail?.phoneNumberConfirmed && (
                <Button
                  color='default'
                  label='Tekrar SMS Doğrulama Gönder'
                  elementType='primary'
                  onClick={sendSmsConfirmation}
                />
              )}
            </SendAgainButtons>
          </ButtonSideContainer>
        }
        onSuccessButtonText='Düzenle'
        onSuccessButtonClick={() => {
          history.push(`/kullanici/${userState.userDetail?.userId}`);
        }}
        onDefaultButtonText={
          userState.userDetail?.status === 1 ? 'Pasif Yap' : 'Aktif Yap'
        }
        onDefaultButtonClick={setUserStatusOpposite}
        additionalButton={!isImpersonating ? loginForUser : <></>}
        bottomSide={
          <UserActivitiesContainer>
            <UserActivitiesTitle>
              Kullanıcının Son Hareketleri
            </UserActivitiesTitle>
            {userState.userDetail?.userActivities?.map(
              (item: ISetUserActivitiesModel) => {
                return (
                  <UserActivitiesRow>
                    <UserActivitiesDateColumn>
                      {moment(item.createDate)
                        .tz('Europe/Istanbul')
                        .format('LLL')}
                    </UserActivitiesDateColumn>
                    <UserActivitiesSeperator> - </UserActivitiesSeperator>
                    <UserActivitiesDescColumn>
                      {
                        userState.eventTypes?.filter(
                          x => x.eventType === item.eventType,
                        )[0].description
                      }
                    </UserActivitiesDescColumn>
                  </UserActivitiesRow>
                );
              },
            )}
          </UserActivitiesContainer>
        }
      />
    </>
  );
};

export default UserSummary;

const UserInformationTable = styled.div(() => [
  tw`flex flex-col border-bottom[1px solid] border-grey-200 `,
]);
const UserInformationRow = styled.div(() => [
  tw`grid grid-cols-12 border-top[1px solid] border-grey-200 bg-white-500`,
]);
const UserInformationEvenRow = styled.div(() => [
  tw`grid grid-cols-12 border-top[1px solid] border-grey-200 bg-white-600`,
]);
const InformationRowLeft = styled.div(() => [
  tw`col-span-4 flex justify-start items-center padding[9px 14px] border-right[1px solid] border-grey-200 text-sm font-medium`,
]);
const InformationRowRight = styled.div(() => [
  tw`col-span-8 justify-start items-center padding[9px 10px] text-sm font-normal flex flex-row gap-1`,
]);
const ButtonSideContainer = styled.div(() => [
  tw`border-bottom[1px solid] border-grey-200 p-5`,
]);
const ConfirmationLabelSideContainer = styled.div(() => [
  tw`flex flex-row gap-2`,
]);
const SendAgainButtons = styled.div(() => [tw`flex flex-col mt-3 gap-2`]);
const UserActivitiesContainer = styled.div(() => [
  tw`flex flex-col border-top[1px solid] border-grey-200`,
]);
const UserActivitiesTitle = styled.div(() => [
  tw`text-info-500 font-medium text-base pl-5 pt-3`,
]);
const UserActivitiesRow = styled.div(() => [
  tw`grid grid-cols-12 padding[8px 20px] border-bottom[1px solid] border-grey-200`,
]);
const UserActivitiesDateColumn = styled.div(() => [
  tw`col-span-4 flex justify-start items-center`,
]);
const UserActivitiesDescColumn = styled.div(() => [
  tw`col-span-7 flex justify-start items-center`,
]);
const UserActivitiesSeperator = styled.div(() => [
  tw`col-span-1 flex justify-start items-center text-grey-200 text-3xl`,
]);
const UserConfirmationLabel = styled.div(
  (props: UserConfirmationLabelProps) => [
    tw`border-radius[100px] font-medium text-xs padding[4px 10px] sm:padding[4px 8px] w-auto`,
    props.isConfirmed
      ? tw`bg-success-100 text-success-500`
      : tw`bg-danger-100 text-danger-500`,
  ],
);
