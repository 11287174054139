import { CombinedState, combineReducers, Reducer } from 'redux';
import { all } from 'redux-saga/effects';
import { IApplicationState } from 'src/store/ducks/types';
import { userReducer } from './user/reducers';
import userSaga from './user/sagas';
import { roleReducer } from './role/reducers';
import roleSaga from './role/sagas';
import accountSaga from './account/sagas';
import { accountReducer } from './account/reducers';
import { AnyAccountAction } from './account/action';
import { courierReducer } from './courier/reducers';
import courierSaga from './courier/sagas';
import { vendorReducer } from './vendor/reducers';
import vendorSaga from './vendor/sagas';
import appSaga from './app/sagas';
import { appReducer } from './app/reducers';
import serviceSaga from './service/sagas';
import { serviceReducer } from './service/reducers';

export const createRootReducer: () => Reducer<
  CombinedState<IApplicationState>,
  AnyAccountAction
> = () => {
  const reducers = {
    account: accountReducer,
    role: roleReducer,
    user: userReducer,
    courier: courierReducer,
    vendor: vendorReducer,
    app: appReducer,
    service: serviceReducer,
  };

  return combineReducers(reducers);
};

export function* rootSaga() {
  yield all([
    accountSaga(),
    roleSaga(),
    userSaga(),
    courierSaga(),
    vendorSaga(),
    appSaga(),
    serviceSaga(),
  ]);
}
