const SUB_DOMAIN: string = '/users';

export const GET_USER_LIST: string = `${SUB_DOMAIN}`;
export const SET_USER: string = `${SUB_DOMAIN}`;
export const GET_USER: string = `${SUB_DOMAIN}`;
export const UPDATE_USER: string = `${SUB_DOMAIN}`;
export const DELETE_USER: string = `${SUB_DOMAIN}`;
export const GET_USER_AUTOCOMPLETE_LIST: string = `${SUB_DOMAIN}/autocomplete`;
export const GET_USER_STATISTIC: string = `${SUB_DOMAIN}/statistics`;
export const GET_USER_ACTIVITIES: string = `${SUB_DOMAIN}`;
