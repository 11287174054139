import { PayloadMetaAction, TypeConstant } from 'typesafe-actions';
import { IGetVendorListReqModel } from './types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { VENDOR_ACTION_TYPES } from './constants';
import { getVendorListFetchResolver } from 'src/httpClient/resolvers/vendor';
import { fetchSetVendorList } from './action';

function* handleGetVendorListFetch(
  action: PayloadMetaAction<TypeConstant, IGetVendorListReqModel, unknown>,
): Generator {
  try {
    const res: any | any = yield call(
      getVendorListFetchResolver,
      action.payload,
    );
    if (res) {
      yield put(fetchSetVendorList(res));
    }
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}
function* watchFetchVendorRequest(): Generator {
  yield takeEvery(
    VENDOR_ACTION_TYPES.GET_VENDOR_LIST,
    handleGetVendorListFetch,
  );
}

export default function* vendorSaga(): Generator {
  yield all([fork(watchFetchVendorRequest)]);
}
