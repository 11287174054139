import { PayloadMetaAction, TypeConstant } from 'typesafe-actions';
import {
  IDeleteUserReqModel,
  IGetUserActivitiesReqModel,
  IGetUserAutocompleteListReqModel,
  IGetUserListReqModel,
  IGetUserReqModel,
  IGetUserStatisticReqModel,
  ISendUserMailConfirmationReqModel,
  ISendUserSMSConfirmationReqModel,
  ISetUserListRawModel,
  ISetUserReqModel,
  ISetUserStatusReqModel,
} from './types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { USER_ACTION_TYPES } from './constants';
import {
  deleteUserFetchResolver,
  getUserActivitiesFetchResolver,
  getUserAutoCompleteListFetchResolver,
  getUserFetchResolver,
  getUserListFetchResolver,
  getUserRolesFetchResolver,
  getUserStatisticFetchResolver,
  sendUserMailConfirmationResolver,
  sendUserSMSConfirmationResolver,
  setUserFetchResolver,
  setUserStatusFetchResolver,
  updateUserFetchResolver,
} from 'src/httpClient/resolvers/user';
import {
  fetchGetUser,
  fetchGetUsersStatisticSuccess,
  fetchGetUserSuccess,
  fetchSetUserAutoCompleteList,
  fetchSetUserList,
  setUserActivities,
} from './action';
import { IAccountRawModel } from '../account/types';
import history from 'src/common/history';
import { setNotification } from '../app/action';

// General GET_USER_LIST
function* handleGetUserListFetch(
  action: PayloadMetaAction<TypeConstant, IGetUserListReqModel, unknown>,
): Generator {
  try {
    const res: ISetUserListRawModel | any = yield call(
      getUserListFetchResolver,
      action.payload,
    );
    if (res) {
      let tempUserListObj = { ...res, data: [] };
      res.data.forEach((element: IAccountRawModel) => {
        tempUserListObj.data.push({
          ...element,
          nameSurname: element.firstName + ' ' + element.lastName,
        });
      });
      yield put(fetchSetUserList(tempUserListObj));
    } else {
      console.log('fetchSetUserList response gelmedi');
    }
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}

// Quich search bar with autocomplete GET_USER_LIST
function* handleGetUserAutoCompleteListFetch(
  action: PayloadMetaAction<
    TypeConstant,
    IGetUserAutocompleteListReqModel,
    unknown
  >,
): Generator {
  try {
    const res: ISetUserListRawModel | any = yield call(
      getUserAutoCompleteListFetchResolver,
      action.payload,
    );
    if (res) {
      let tempUserListObj = { ...res, data: [] };
      res.data.forEach(element => {
        let email = element.email || '';
        let phoneNumber = element.phone || '';
        tempUserListObj.data.push({
          ...element,
          name: element.value,
          email: email + ' ' + phoneNumber,
        });
      });
      yield put(fetchSetUserAutoCompleteList(tempUserListObj));
    } else {
      console.log('fetchSetUserList response gelmedi');
    }
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}
// Create new user SET_USER
function* handleSetUserFetch(
  action: PayloadMetaAction<TypeConstant, ISetUserReqModel, unknown>,
): Generator {
  try {
    const res: any = yield call(setUserFetchResolver, action.payload);
    if (res) {
      yield put(
        setNotification({
          message: 'Kullanıcı başarıyla eklendi.',
          type: 'success',
        }),
      );
      history.push(`/kullanici/${res.userId}`);
    }
  } catch (err: any) {
    let errorMessage = err;
    yield put(
      setNotification({
        message: `Kullanıcı eklenirken hata oluştu. Hata : ${errorMessage.data.errors[''][0].description}`,
        type: 'warning',
      }),
    );
  }
}
// Delet user DELETE_USER
function* handleDeleteUserFetch(
  action: PayloadMetaAction<TypeConstant, IDeleteUserReqModel, unknown>,
): Generator {
  try {
    const res: any = yield call(deleteUserFetchResolver, action.payload);
    yield put(
      setNotification({
        message: 'Kullanıcı başarıyla silindi.',
        type: 'success',
      }),
    );
    if (history.location.pathname === '/kullanicilar') {
      history.push(history.location.pathname + history.location.search);
    } else {
      history.push('/kullanicilar');
    }
  } catch (err: any) {
    let errorMessage = err.message;
    yield put(
      setNotification({
        message: 'Kullanıcı silinirken hata oluştu.',
        type: 'error',
      }),
    );
  }
}
// Update UPDATE_USER
function* handleUpdateUserFetch(
  action: PayloadMetaAction<TypeConstant, ISetUserReqModel, unknown>,
): Generator {
  try {
    const res: ISetUserReqModel | any = yield call(
      updateUserFetchResolver,
      action.payload,
      action.meta,
    );
    yield put(
      setNotification({
        message: 'Kullanıcı başarıyla güncellendi.',
        type: 'success',
      }),
    );
  } catch (err: any) {
    let errorMessage = err.message;
    yield put(
      setNotification({
        message: 'Kullanıcı güncellenirken hata oluştu.',
        type: 'error',
      }),
    );
  }
}
// Get selected user detail GET_USER
function* handleGetUserFetch(
  action: PayloadMetaAction<TypeConstant, IGetUserReqModel, unknown>,
): Generator {
  try {
    const userDetail: IAccountRawModel | any = yield call(
      getUserFetchResolver,
      action.payload,
    );
    if (userDetail) {
      const userRoleIds: IAccountRawModel | any = yield call(
        getUserRolesFetchResolver,
        action.payload,
      );
      yield put(
        fetchGetUserSuccess({ ...userDetail.data, roleIds: userRoleIds.data }),
      );
    }
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}
function* handleGetUserActivitiesFetch(
  action: PayloadMetaAction<TypeConstant, IGetUserActivitiesReqModel, unknown>,
): Generator {
  try {
    const userActivities: any | any = yield call(
      getUserActivitiesFetchResolver,
      action.payload,
    );
    if (userActivities) {
      yield put(setUserActivities(userActivities.data));
    }
  } catch (err: any) {
    let nullObj;
    yield put(setUserActivities(nullObj));
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}

// User page statistic side GET_STATISTIC
function* handleGetUserStatisticFetch(): Generator {
  try {
    const res: IGetUserStatisticReqModel | any = yield call(
      getUserStatisticFetchResolver,
    );
    if (res) {
      yield put(fetchGetUsersStatisticSuccess(res));
    } else {
      console.log('fetchGetUserStatistic response gelmedi');
    }
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}

function* handleSetUserStatusFetch(
  action: PayloadMetaAction<TypeConstant, ISetUserStatusReqModel, unknown>,
): Generator {
  try {
    yield call(setUserStatusFetchResolver, action.payload);
    yield put(fetchGetUser({ userid: action.payload.userid }));
    yield put(
      setNotification({
        message: 'Kullanıcı başarıyla güncellendi.',
        type: 'success',
      }),
    );
  } catch (err: any) {
    let errorMessage = err.message;
    yield put(
      setNotification({
        message: `Kullanıcı güncellenirken hata oluştu.${errorMessage}`,
        type: 'error',
      }),
    );
  }
}
function* sendUserMailConfirmationFetch(
  action: PayloadMetaAction<
    TypeConstant,
    ISendUserMailConfirmationReqModel,
    unknown
  >,
): Generator {
  try {
    yield call(sendUserMailConfirmationResolver, action.payload);
    yield put(fetchGetUser({ userid: action.payload.userid }));
    yield put(
      setNotification({
        message: 'E-mail başarıyla gönderildi.',
        type: 'success',
      }),
    );
  } catch (err: any) {
    let errorMessage = err.message;
    yield put(
      setNotification({
        message: `E-mail gönderilirken hata oluştu.${errorMessage}`,
        type: 'error',
      }),
    );
  }
}
function* sendUserSMSConfirmationFetch(
  action: PayloadMetaAction<
    TypeConstant,
    ISendUserSMSConfirmationReqModel,
    unknown
  >,
): Generator {
  try {
    yield call(sendUserSMSConfirmationResolver, action.payload);
    yield put(fetchGetUser({ userid: action.payload.userid }));
    yield put(
      setNotification({
        message: 'SMS başarıyla gönderildi.',
        type: 'success',
      }),
    );
  } catch (err: any) {
    let errorMessage = err.message;
    yield put(
      setNotification({
        message: `Sms gönderilirken hata oluştu.${errorMessage}`,
        type: 'error',
      }),
    );
  }
}

function* watchFetchUserRequest(): Generator {
  yield takeEvery(USER_ACTION_TYPES.GET_USER_LIST, handleGetUserListFetch);
  yield takeEvery(USER_ACTION_TYPES.SET_USER, handleSetUserFetch);
  yield takeEvery(USER_ACTION_TYPES.UPDATE_USER, handleUpdateUserFetch);
  yield takeEvery(
    USER_ACTION_TYPES.SEND_USER_EMAIL_CONFIRMATION,
    sendUserMailConfirmationFetch,
  );
  yield takeEvery(
    USER_ACTION_TYPES.SEND_USER_SMS_CONFIRMATION,
    sendUserSMSConfirmationFetch,
  );
  yield takeEvery(USER_ACTION_TYPES.GET_USER, handleGetUserFetch);
  yield takeEvery(USER_ACTION_TYPES.DELETE_USER, handleDeleteUserFetch);
  yield takeEvery(USER_ACTION_TYPES.SET_USER_STATUS, handleSetUserStatusFetch);
  yield takeEvery(
    USER_ACTION_TYPES.GET_USER_ACTIVITIES,
    handleGetUserActivitiesFetch,
  );
  yield takeEvery(
    USER_ACTION_TYPES.GET_USER_AUTOCOMPLETE_LIST,
    handleGetUserAutoCompleteListFetch,
  );
  yield takeEvery(
    USER_ACTION_TYPES.GET_USER_STATISTIC,
    handleGetUserStatisticFetch,
  );
}

export default function* userSaga(): Generator {
  yield all([fork(watchFetchUserRequest)]);
}
