import { AnyAppAction } from './action';
import { APP_ACTION_TYPES } from './constants';
import { IAppState } from './types';

export const initialState: IAppState = {
  notification: {
    full: true,
    type: 'success',
    message: '',
  },
  helmetIdentifier: '',
  activeCalls: [],
};

export const appReducer = (
  state: IAppState = initialState,
  action: AnyAppAction,
): IAppState => {
  switch (action.type) {
    case APP_ACTION_TYPES.SET_NOTIFICATION:
      return {
        ...state,
        notification: { ...state.notification, ...action.payload },
      };
    case APP_ACTION_TYPES.SET_CALL_NAME_FOR_LOADER:
      return {
        ...state,
        activeCalls: action.payload,
      };
    case APP_ACTION_TYPES.SET_HELMET_IDENTIFIER:
      return {
        ...state,
        helmetIdentifier: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
