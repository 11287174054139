import React, { useEffect } from 'react';
import tw from 'twin.macro';
import styled, { css } from 'styled-components';
import {
  Button,
  PageTitle,
  Card,
  TabMenu,
  SeeAll,
  Icon,
  ConfirmModal,
} from 'roadrunner.uiframework';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from 'src/store/ducks/types';
import FormTextInput from 'src/components/TextBox';
import { Link } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import FormSelectInput from 'src/components/SelectBox';
import history from 'src/common/history';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { setHelmetIdentifier } from 'src/store/ducks/app/action';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
import {
  clearServiceDetail,
  fetchCreateService,
  fetchGetDropDownsValues,
  fetchGetServiceList,
  fetchServiceDetail,
  fetchUpdateService,
} from 'src/store/ducks/service/action';
import FormTextArea from 'src/components/TextArea';

const deliveryAttempt = [
  {
    value: 0,
    label: 0,
  },
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
  {
    value: 6,
    label: 6,
  },
];
const schema = yup.object({
  name: yup.string().required(),
  shortName: yup.string().required(),
  serviceCode: yup.string().required(),
  condition: yup.string().required(),
  retryPolicy: yup.object().required(),
  transferType: yup.object().required(),
  deliveryTimeType: yup.object().required(),
  collectingType: yup.object().required(),
});
const ServiceDetail: React.FC = () => {
  const dispatch: Dispatch = useDispatch();
  const { serviceid } = useParams<{ serviceid: string }>();
  const serviceList = useSelector(
    (state: IApplicationState) => state.service.serviceList,
  );
  const collectionTypes = useSelector(
    (state: IApplicationState) => state.service.collectingTypes,
  );
  const transferTypes = useSelector(
    (state: IApplicationState) => state.service.transferTypes,
  );
  const deliveryTimes = useSelector(
    (state: IApplicationState) => state.service.deliveryTime,
  );
  const serviceDetail = useSelector(
    (state: IApplicationState) => state.service.serviceDetail,
  );
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<any> = data => {
    let serviceInformation = {
      name: data.name,
      shortName: data.shortName,
      serviceCode: data.serviceCode,
      retryPolicy: data.retryPolicy.value,
      transferType: data.transferType.value,
      deliveryTimeType: data.deliveryTimeType.value,
      collectingType: data.collectingType.value,
      condition: data.condition,
    };
    if (serviceid) {
      dispatch(fetchUpdateService(serviceInformation, { id: serviceid }));
    } else {
      dispatch(fetchCreateService(serviceInformation));
    }
  };

  useEffect(() => {
    dispatch(
      fetchGetServiceList({
        Take: 5,
        IsDescending: false,
        OrderBy: 'createDate',
      }),
    );
    serviceid && dispatch(fetchServiceDetail({ id: serviceid }));
    Object.keys(collectionTypes).length == 0 &&
      dispatch(fetchGetDropDownsValues());
  }, []);

  useEffect(() => {
    if (serviceDetail) {
      reset({
        name: serviceDetail.name,
        shortName: serviceDetail.shortName,
        serviceCode: serviceDetail.serviceCode,
        condition: serviceDetail.condition,
        retryPolicy: {
          value: serviceDetail.retryPolicy,
          label: serviceDetail.retryPolicy,
        },
        transferType: {
          value: serviceDetail.transferType,
          label: serviceDetail.transferTypeDescription,
        },
        deliveryTimeType: {
          value: serviceDetail.deliveryTime,
          label: serviceDetail.deliveryTimeDescription,
        },
        collectingType: {
          value: serviceDetail.collectingType,
          label: serviceDetail.collectingTypeDescription,
        },
      });
    }
    serviceDetail.name && dispatch(setHelmetIdentifier(serviceDetail.name));
  }, [serviceDetail]);

  useEffect(
    () => () => {
      dispatch(clearServiceDetail());
      dispatch(setHelmetIdentifier(''));
    },
    [],
  );
  return (
    <>
      {console.log('sevic', serviceDetail.retryPolicy)}
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageTitleContainer>
          <PageTitle
            title={serviceid ? serviceDetail.name : 'Yeni Hizmet'}
            icon={
              <Link to='/hizmetler'>
                <Icon name='arrow-left' />
              </Link>
            }
          >
            <TitleButtonContainer>
              <Button
                color='default'
                icon=''
                label='Vazgeç'
                elementType='primary'
                onClick={e => {
                  e.preventDefault();
                  history.push(`/hizmetler`);
                }}
              />
              <Button
                color='success'
                icon=''
                label='Hizmeti Kaydet'
                elementType='secondary'
              />
            </TitleButtonContainer>
          </PageTitle>
        </PageTitleContainer>
        <NewUserContainer>
          <LastAddedList>
            <Card>
              <SectionTitle>Son Eklenenler</SectionTitle>
              <LastUsersContainer>
                {serviceList.map((item, index) => (
                  <>
                    <TabMenu
                      key={index}
                      icon='chevron-right'
                      text={item.name || ''}
                      subText={
                        `${item.vendors?.length} Vendor Hizmeti Kullanıyor` ||
                        ''
                      }
                      onClick={() => {
                        history.push(`/hizmet/${item.id}`);
                      }}
                    />
                  </>
                ))}
              </LastUsersContainer>
              <SeeAll
                seeAll={
                  <Link to='/hizmetler'>Tüm Kullanıcı Listesine Git</Link>
                }
              />
            </Card>
          </LastAddedList>
          <FormUserContainer>
            <Card>
              <SectionTitle>Hizmet Bilgileri</SectionTitle>
              <FormSideContainer>
                <FormTextInput
                  control={control}
                  name='name'
                  placeholder='Hizmet Adı'
                  size='large'
                  setValue={setValue}
                  required={errors.name}
                />
                <FormTextInput
                  control={control}
                  name='shortName'
                  placeholder='Kısa Adı'
                  size='large'
                  setValue={setValue}
                  required={errors.shortName}
                />
                <FormTextInput
                  control={control}
                  name='serviceCode'
                  placeholder='Hizmet Kodu'
                  size='large'
                  setValue={setValue}
                  required={errors.serviceCode}
                />
                <FormTextArea
                  control={control}
                  name='condition'
                  placeholder='Şartlar'
                  setValue={setValue}
                  required={errors.condition}
                />
              </FormSideContainer>
            </Card>
            <Card>
              <SectionTitle>Değerler</SectionTitle>
              <FormSideContainer>
                <FormSelectInput
                  placeholder='Teslimat Süresi'
                  size='large'
                  name='deliveryTimeType'
                  options={deliveryTimes}
                  control={control}
                  hasDefaultValue={serviceDetail.deliveryTime}
                />
                <FormSelectInput
                  placeholder='Teslim Tekrar Adedi'
                  size='large'
                  name='retryPolicy'
                  options={deliveryAttempt}
                  control={control}
                  hasDefaultValue={
                    serviceDetail.retryPolicy !== null &&
                    serviceDetail.retryPolicy !== undefined
                  }
                />
                <FormSelectInput
                  placeholder='Toplama Şekli'
                  size='large'
                  name='collectingType'
                  options={collectionTypes}
                  control={control}
                  hasDefaultValue={serviceDetail.collectingType}
                />
                <FormSelectInput
                  placeholder='Dağıtım Şekli'
                  size='large'
                  name='transferType'
                  options={transferTypes}
                  control={control}
                  hasDefaultValue={serviceDetail.transferType}
                />
              </FormSideContainer>
            </Card>
          </FormUserContainer>
        </NewUserContainer>
      </form>
    </>
  );
};

export default ServiceDetail;
const PageTitleContainer = styled.div(() => [tw`flex items-center mt-4 `]);
const TitleButtonContainer = styled.div(() => [
  css`
    ${tw`flex items-center gap-2`}
    button {
      .icon {
        ${tw`text-base`}
      }
    }
  `,
]);
const NewUserContainer = styled.div(() => [
  tw`grid grid-cols-12 xl:padding[20px 135px] sm:padding[20px 65px]`,
]);
const SectionTitle = styled.span(() => [tw`text-sm text-grey-500`]);
const LastUsersContainer = styled.div(() => [
  css`
    ${tw`flex flex-col gap-3 mt-2`}
    button {
      ${tw`w-full`}
    }
  `,
]);
const FormSideContainer = styled.div(() => [
  css`
    ${tw`flex flex-col gap-2 mt-3`}
    input {
      ${tw`w-full`}
    }
  `,
]);
const LastAddedList = styled.div(() => [
  tw`xl:col-span-4 xl:block md:hidden sm:hidden`,
]);
const FormUserContainer = styled.div(() => [
  tw`xl:col-span-8 lg:col-span-12 md:col-span-12 sm:col-span-12 flex flex-col gap-3 ml-6`,
]);

const DeleteUser = styled.div(() => [tw`w-4/12`]);

const modalStyle = {
  overlay: {
    position: 'fixed',
    opacity: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(34, 43, 69, 0.5)',
    zIndex: 10,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 0,
    padding: 0,
    width: '370px',
  },
};
