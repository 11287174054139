import { action } from 'typesafe-actions';
import { SERVICE_ACTION_TYPES } from './constants';
import {
  IGetServiceListReqModel,
  IServiceDetailReqModel,
  IServiceListRawModel,
  IServiceRawModel,
} from './types';

export const fetchGetServiceList = (params: IGetServiceListReqModel) =>
  action(SERVICE_ACTION_TYPES.GET_SERVICE_LIST, params);
export const fetchSetServiceList = (params: IServiceListRawModel) =>
  action(SERVICE_ACTION_TYPES.SET_SERVICE_LIST, params);
export const fetchServiceDetail = (params: IServiceDetailReqModel) =>
  action(SERVICE_ACTION_TYPES.GET_SERVICE_DETAIL, params);
export const fetchSetServiceDetail = (params: IServiceRawModel) =>
  action(SERVICE_ACTION_TYPES.SET_SERVICE_DETAIL, params);
export const fetchGetDropDownsValues = () =>
  action(SERVICE_ACTION_TYPES.GET_SERVICE_DROPDOWNS);
export const fetchSetDropDownsValues = (params: any) =>
  action(SERVICE_ACTION_TYPES.SET_SERVICE_DROPDOWNS, params);
export const clearServiceDetail = () =>
  action(SERVICE_ACTION_TYPES.CLEAR_SERVICE_DETAIL);
export const fetchUpdateService = (
  payload: IServiceRawModel,
  params: IServiceDetailReqModel,
) => action(SERVICE_ACTION_TYPES.UPDATE_SERVICE, payload, params);
export const fetchCreateService = (payload: IServiceRawModel) =>
  action(SERVICE_ACTION_TYPES.CREATE_SERVICE, payload);
export const fetchDeleteService = (payload: IServiceDetailReqModel) =>
  action(SERVICE_ACTION_TYPES.DELETE_SERVICE, payload);
export type AnyAccountAction =
  | ReturnType<typeof fetchGetServiceList>
  | ReturnType<typeof fetchSetServiceList>
  | ReturnType<typeof fetchSetServiceDetail>
  | ReturnType<typeof fetchGetDropDownsValues>
  | ReturnType<typeof fetchSetDropDownsValues>
  | ReturnType<typeof fetchUpdateService>
  | ReturnType<typeof fetchDeleteService>
  | ReturnType<typeof fetchCreateService>
  | ReturnType<typeof clearServiceDetail>
  | ReturnType<typeof fetchServiceDetail>;
