import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import appConfigs from 'src/configs/app';
import { IApplicationState } from 'src/store/ducks/types';

export default function HelmetTittle(props) {
  const { route } = props;
  const helmetIdentifier = useSelector(
    (state: IApplicationState) => state.app.helmetIdentifier,
  );
  const baseTitle: string = `${appConfigs.appName}`;
  const fullTitle: string = helmetIdentifier
    ? `${helmetIdentifier} - ${baseTitle} - Scotty `
    : route.title && route.title.length
    ? `${route.title} - ${baseTitle} - Scotty `
    : `${baseTitle} - Scotty`;
  return (
    <>
      <Helmet>
        <title>{fullTitle}</title>
      </Helmet>
    </>
  );
}
