import React, { useEffect } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from 'src/store/ducks/types';
import history from 'src/common/history';
import ItemSummary from 'src/components/ItemSummary';
import { Icon } from 'roadrunner.uiframework';
import { fetchServiceDetail } from 'src/store/ducks/service/action';

type IServiceSummary = {
  onClose?: Function;
  selectedID: string;
};

const ServiceSummary: React.FC<IServiceSummary> = (props: IServiceSummary) => {
  const serviceDetail = useSelector(
    (state: IApplicationState) => state.service.serviceDetail,
  );

  const { onClose, selectedID } = props;
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    getServiceDetail();
  }, []);

  const getServiceDetail = () => {
    if (selectedID) {
      dispatch(fetchServiceDetail({ id: selectedID }));
    }
  };

  return (
    <>
      <ItemSummary
        onClose={onClose}
        title={<Title>{serviceDetail.name}</Title>}
        tableSide={
          <ServiceInformationTable>
            <ServiceInformationEvenRow>
              <InformationRowLeft>Kısa Adı:</InformationRowLeft>
              <InformationRowRight>
                {serviceDetail.shortName}
              </InformationRowRight>
            </ServiceInformationEvenRow>
            <ServiceInformationRow>
              <InformationRowLeft>Hizmet Adı:</InformationRowLeft>
              <InformationRowRight>{serviceDetail.name}</InformationRowRight>
            </ServiceInformationRow>
            <ServiceInformationEvenRow>
              <InformationRowLeft>Hizmet Kodu:</InformationRowLeft>
              <InformationRowRight>
                {serviceDetail.serviceCode}
              </InformationRowRight>
            </ServiceInformationEvenRow>
            <ServiceInformationRow>
              <InformationRowLeft>Şartlar:</InformationRowLeft>
              <InformationRowRight>
                {serviceDetail.condition}
              </InformationRowRight>
            </ServiceInformationRow>
            <ServiceInformationEvenRow>
              <InformationRowLeft>Teslimat Süresi:</InformationRowLeft>
              <InformationRowRight>
                {serviceDetail.deliveryTime} Saat
              </InformationRowRight>
            </ServiceInformationEvenRow>
            <ServiceInformationRow>
              <InformationRowLeft>Toplama Şekli:</InformationRowLeft>
              <InformationRowRight>
                {serviceDetail.collectingTypeDescription}
              </InformationRowRight>
            </ServiceInformationRow>
            <ServiceInformationEvenRow>
              <InformationRowLeft>Dağıtım Şekli:</InformationRowLeft>
              <InformationRowRight>
                {serviceDetail.transferTypeDescription}
              </InformationRowRight>
            </ServiceInformationEvenRow>
          </ServiceInformationTable>
        }
        onSuccessButtonText='Düzenle'
        onSuccessButtonClick={() => {
          history.push(`/hizmet/${serviceDetail.id}`);
        }}
        bottomSide={
          <ServiceActivitiesContainer>
            <ServiceActivitiesTitle>
              Hizmetten Yararlanan Vendorlar
            </ServiceActivitiesTitle>
            {serviceDetail.vendors?.map((item: any) => {
              return (
                <ServiceActivitiesRow>
                  <ServiceActivitiesDateColumn>
                    {item.name}
                    <Icon name={'chevron-right'} />
                  </ServiceActivitiesDateColumn>
                </ServiceActivitiesRow>
              );
            })}
          </ServiceActivitiesContainer>
        }
      />
    </>
  );
};

export default ServiceSummary;

const ServiceInformationTable = styled.div(() => [
  tw`flex flex-col border-bottom[1px solid] border-grey-200 `,
]);
const ServiceInformationRow = styled.div(() => [
  tw`grid grid-cols-12 border-top[1px solid] border-grey-200 bg-white-500`,
]);
const ServiceInformationEvenRow = styled.div(() => [
  tw`grid grid-cols-12 border-top[1px solid] border-grey-200 bg-white-600`,
]);
const InformationRowLeft = styled.div(() => [
  tw`col-span-4 flex justify-start items-center padding[9px 14px] border-right[1px solid] border-grey-200 text-sm font-medium`,
]);
const InformationRowRight = styled.div(() => [
  tw`col-span-8 justify-start items-center padding[9px 10px] text-sm font-normal flex flex-row gap-1`,
]);
const ServiceActivitiesContainer = styled.div(() => [
  tw`flex flex-col border-top[1px solid] border-grey-200`,
]);
const ServiceActivitiesTitle = styled.div(() => [
  tw`text-info-500 font-medium text-base pl-5 pt-3`,
]);
const ServiceActivitiesRow = styled.div(() => [
  tw`flex padding[8px 20px] border-bottom[1px solid] border-grey-200 mt-2 cursor-pointer`,
]);
const ServiceActivitiesDateColumn = styled.div(() => [
  tw`w-full flex justify-between items-center`,
]);
const Title = styled.div(() => [tw`text-2xl`]);
