import { PayloadMetaAction, TypeConstant } from 'typesafe-actions';
import {
  IQRLoginRawModel,
  IQRLoginReqModel,
  IAccountState,
  IMenuReqModel,
} from './types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ACCOUNT_ACTION_TYPES } from './constants';
import {
  getQRLoginStringFetchResolver,
  getCurrentUserFetchResolver,
  getMenuFetchResolver,
} from 'src/httpClient/resolvers/account';
import {
  fetchSetQRLoginString,
  fetchSetUserInformationSuccess,
} from 'src/store/ducks/account/action';

function* handleUserInformationFetch(): Generator {
  try {
    const res: IAccountState | any = yield call(getCurrentUserFetchResolver);
    if (res) {
      yield put(fetchSetUserInformationSuccess(res));
    } else {
      console.log('USER BILGISI CEKILEMEDI');
    }
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}
function* handleQRLoginFetch(
  action: PayloadMetaAction<TypeConstant, IQRLoginReqModel, unknown>,
): Generator {
  try {
    if (action.payload) {
      const res: IQRLoginRawModel | any = yield call(
        getQRLoginStringFetchResolver,
        action.payload,
      );
      yield put(fetchSetQRLoginString(res));
    }
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}
function* handleMenuFetch(
  action: PayloadMetaAction<TypeConstant, IMenuReqModel, unknown>,
): Generator {
  try {
    const res: any | any = yield call(getMenuFetchResolver, action.payload);
    //TODO:res type belirle call fail geliyor
  } catch (err: any) {
    let errorMessage = err.message;
    console.log('errorMessage', errorMessage);
  }
}

function* watchFetchAccountRequest(): Generator {
  yield takeEvery(
    ACCOUNT_ACTION_TYPES.GET_USER_INFORMATION,
    handleUserInformationFetch,
  );
  yield takeEvery(ACCOUNT_ACTION_TYPES.GET_QR_LOGIN_STRING, handleQRLoginFetch);
  yield takeEvery(ACCOUNT_ACTION_TYPES.GET_MENU, handleMenuFetch);
}

export default function* accountSaga(): Generator {
  yield all([fork(watchFetchAccountRequest)]);
}
