import { AxiosInstance, AxiosRequestConfig, Method } from 'axios';
import { REQUEST_METHODS } from 'src/httpClient/constants';
import defaultHttpClient from 'src/httpClient';

export const createApiEndpoint = (
  url: string,
  method?: string,
  data?: unknown, // request body params
  qParams?: URLSearchParams | unknown, // request query params
  additionalConfigs?: AxiosRequestConfig,
  httpClient?: AxiosInstance,
) => {
  const requestMethod: string = method || REQUEST_METHODS.GET;
  const httpClientInstance: AxiosInstance = httpClient || defaultHttpClient;
  return httpClientInstance(url, {
    method: requestMethod as Method,
    data,
    ...(qParams ? { params: qParams } : null),
    ...additionalConfigs,
  });
};

export default createApiEndpoint;
