import { AxiosError, AxiosResponse } from 'axios';

import {
  IDeleteUserReqModel,
  IGetUserActivitiesReqModel,
  IGetUserAutocompleteListReqModel,
  IGetUserListReqModel,
  IGetUserReqModel,
  IGetUserRolesReqModel,
  ISendUserMailConfirmationReqModel,
  ISendUserSMSConfirmationReqModel,
  ISetUserReqModel,
  ISetUserStatusReqModel,
  IUserState,
} from 'src/store/ducks/user/types';
import {
  deleteUserFetch,
  getUserActivitiesFetch,
  getUserAutoCompleteListFetch,
  getUserFetch,
  getUserListFetch,
  getUserRolesFetch,
  getUserStatisticFetch,
  sendUserMailConfirmationFetch,
  sendUserSMSConfirmationFetch,
  setUserFetch,
  setUserStatusFetch,
  updateUserFetch,
} from '../endpoints/user';

export const getUserListFetchResolver: (
  params: IGetUserListReqModel,
) => Promise<AxiosResponse<IUserState>> = (params: IGetUserListReqModel) => {
  return getUserListFetch(params)
    .then((res: AxiosResponse<IUserState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const getUserAutoCompleteListFetchResolver: (
  params: IGetUserAutocompleteListReqModel,
) => Promise<AxiosResponse<IUserState>> = (
  params: IGetUserAutocompleteListReqModel,
) => {
  return getUserAutoCompleteListFetch(params)
    .then((res: AxiosResponse<IUserState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const getUserStatisticFetchResolver: () => Promise<
  AxiosResponse<IUserState>
> = () => {
  return getUserStatisticFetch()
    .then((res: AxiosResponse<IUserState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const setUserFetchResolver: (
  payload: ISetUserReqModel,
) => Promise<AxiosResponse<any>> = (payload: ISetUserReqModel) => {
  return setUserFetch(payload)
    .then((res: AxiosResponse<any>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const deleteUserFetchResolver: (
  params: IDeleteUserReqModel,
) => Promise<AxiosResponse<any>> = (params: IDeleteUserReqModel) => {
  return deleteUserFetch(params)
    .then((res: AxiosResponse<any>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const getUserFetchResolver: (
  params: IGetUserReqModel,
) => Promise<AxiosResponse<any>> = (params: IGetUserReqModel) => {
  return getUserFetch(params)
    .then((res: AxiosResponse<any>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const getUserRolesFetchResolver: (
  params: IGetUserRolesReqModel,
) => Promise<AxiosResponse<any>> = (params: IGetUserRolesReqModel) => {
  return getUserRolesFetch(params)
    .then((res: AxiosResponse<any>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const getUserActivitiesFetchResolver: (
  params: IGetUserActivitiesReqModel,
) => Promise<AxiosResponse<any>> = (params: IGetUserActivitiesReqModel) => {
  return getUserActivitiesFetch(params)
    .then((res: AxiosResponse<any>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const updateUserFetchResolver: (
  payload: ISetUserReqModel,
  params: any,
) => Promise<AxiosResponse<any>> = (payload: ISetUserReqModel, params: any) => {
  return updateUserFetch(payload, params)
    .then((res: AxiosResponse<any>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const setUserStatusFetchResolver: (
  payload: ISetUserStatusReqModel,
) => Promise<AxiosResponse<any>> = (payload: ISetUserStatusReqModel) => {
  return setUserStatusFetch(payload)
    .then((res: AxiosResponse<any>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const sendUserMailConfirmationResolver: (
  payload: ISendUserMailConfirmationReqModel,
) => Promise<AxiosResponse<any>> = (
  payload: ISendUserMailConfirmationReqModel,
) => {
  return sendUserMailConfirmationFetch(payload)
    .then((res: AxiosResponse<any>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
export const sendUserSMSConfirmationResolver: (
  payload: ISendUserSMSConfirmationReqModel,
) => Promise<AxiosResponse<any>> = (
  payload: ISendUserSMSConfirmationReqModel,
) => {
  return sendUserSMSConfirmationFetch(payload)
    .then((res: AxiosResponse<any>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
