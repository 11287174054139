import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';
import styled, { css } from 'styled-components';
import {
  Logo,
  MenuItem,
  UserSection,
  UserActions,
  PullDownMenuSecondary,
} from 'roadrunner.uiframework';
import { IApplicationState } from 'src/store/ducks/types';
import { useModal } from 'react-modal-hook';
import ReactModal from 'react-modal';
import LoginQr from 'src/components/LoginQr';
import { Dispatch } from 'redux';
import {
  fetchGetQRLoginString,
  fetchMenuRequest,
  fetchSetUserInformation,
} from 'src/store/ducks/account/action';
import useOnClickOutside from 'src/common/hooks/onClickOutside';
import AuthService from 'src/common/auth';
import menu from './dummyMenu';

const Header: React.FC = () => {
  const accountState = useSelector((state: IApplicationState) => state.account);
  const [userDropDDownStatus, setUserDropDDownStatus] = useState(false);
  const ref: any = useRef();
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSetUserInformation());
    dispatch(fetchMenuRequest({}));
  }, []);

  const [showModal, hideModal] = useModal(() => (
    <ReactModal isOpen ariaHideApp={false} style={modalStyle}>
      <LoginQr
        title='QR Code ile Giris Yapın'
        text='Terminal uygulamasına giriş yapmak için lütfen QR kodu okutunuz'
        onClose={hideModal}
        generateQr={getQrCode}
      />
    </ReactModal>
  ));
  useOnClickOutside(ref, () => setUserDropDDownStatus(false));
  const getQrCode: Function = () => {
    dispatch(
      fetchGetQRLoginString({
        scope:
          'openid email profile vendorapi.manage membership.account.authenticated membership.account.unauthenticated membership.manage',
      }),
    );
  };
  const goLogOut: any = () => {
    const auth = new AuthService();
    auth.logout();
  };
  return (
    <HeaderContainer>
      <Logo />
      <MenuItemContainer>
        {menu.map((e, index) => (
          <MenuItem key={index} {...e} />
        ))}
      </MenuItemContainer>
      <div
        onClick={() => {
          setUserDropDDownStatus(true);
        }}
      >
        <UserSection
          name={
            accountState.firstName
              ? accountState.firstName + ' ' + accountState.lastName
              : accountState.email
          }
          contact={
            accountState.email
              ? accountState.email
              : accountState.phoneNumber
              ? accountState.phoneNumber
              : ''
          }
        />
      </div>
      <div ref={ref}>
        <PulldowMenuContainer>
          {userDropDDownStatus && (
            <PullDownMenuSecondary
              items={[
                {
                  date: '20.09.2021',
                  icon: 'edit',
                  label: 'Parola Değiştir',
                  onClick: function noRefCheck() {
                    let returnUrl = window.location.href;
                    window.location.href = `${process.env.REACT_APP__IDENTITY_DOMAIN}/Account/ChangePassword?returnUrl=${returnUrl}`;
                  },
                  text: '',
                },
              ]}
              logout={
                <a className='logout' href='#' onClick={goLogOut}>
                  Çıkış Yap
                </a>
              }
            />
          )}
        </PulldowMenuContainer>
      </div>
      <UserActionContainer>
        <UserActions icon='qr_code' onClick={showModal} />
        <UserActions icon='notification' />
      </UserActionContainer>
    </HeaderContainer>
  );
};

export default Header;
export { PulldowMenuContainer };

const HeaderContainer = styled.div(() => [
  tw`flex justify-between items-center border-bottom-width[1px] border-grey-100 h-11 padding[13px 20px] bg-white-500 fixed w-full z-index[3] top-0`,
]);
const MenuItemContainer = styled.div(() => [tw`flex-1 ml-10`]);
const PulldowMenuContainer = styled.div(() => [
  css`
    .pull-down-menu {
      ${tw`absolute top[60px] right[85px] z-10`}
    }
  `,
]);
const UserActionContainer = styled.div(() => [
  tw`flex items-center`,
  css`
    button {
      margin-left: 8px;
    }
  `,
]);

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 0,
    padding: 0,
    width: window.innerWidth > 1000 ? '33%' : '50%',
  },
  overlay: {
    position: 'fixed',
    opacity: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(34, 43, 69, 0.5)',
    zIndex: 10,
  },
};
