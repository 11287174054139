import { action } from 'typesafe-actions';
import { ACCOUNT_ACTION_TYPES } from './constants';
import {
  IAccountRawModel,
  IMenuReqModel,
  IQRLoginRawModel,
  IQRLoginReqModel,
} from './types';

export const fetchSetUserInformation = () =>
  action(ACCOUNT_ACTION_TYPES.GET_USER_INFORMATION);
export const fetchSetUserInformationSuccess = (payload: IAccountRawModel) =>
  action(ACCOUNT_ACTION_TYPES.SET_USER_INFORMATION, payload);
export const fetchGetQRLoginString = (payload: IQRLoginReqModel) =>
  action(ACCOUNT_ACTION_TYPES.GET_QR_LOGIN_STRING, payload);
export const fetchSetQRLoginString = (payload: IQRLoginRawModel) =>
  action(ACCOUNT_ACTION_TYPES.SET_QR_LOGIN_STRING, payload);
export const fetchMenuRequest = (payload: IMenuReqModel) =>
  action(ACCOUNT_ACTION_TYPES.GET_MENU, payload);
export type AnyAccountAction =
  | ReturnType<typeof fetchSetUserInformation>
  | ReturnType<typeof fetchSetUserInformationSuccess>
  | ReturnType<typeof fetchGetQRLoginString>
  | ReturnType<typeof fetchSetQRLoginString>
  | ReturnType<typeof fetchMenuRequest>;
