import generateDynamicTypes from 'src/common/helpers/generateDynamicTypes';

export const ROLE_ACTION_TYPES = {
  ...generateDynamicTypes(
    [
      'GET_ROLE_LIST',
      'SET_ROLE_LIST',
      'GET_PERMISSION_LIST',
      'SET_PERMISSION_LIST',
      'GET_ROLE_TYPES',
      'SET_ROLE_TYPES',
      'CREATE_ROLE',
      'GET_ROLE',
      'SET_ROLE_DETAIL',
      'GET_ROLE_PERMISSION_LIST',
      'SET_ROLE_PERMISSION_LIST',
      'UPDATE_ROLE',
      'DELETE_ROLE',
      'CLEAR_ROLE_DETAIL',
    ],
    '@@role',
  ),
};
