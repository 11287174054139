import { AxiosError, AxiosResponse } from 'axios';
import {
  IGetVendorListReqModel,
  IVendorState,
} from 'src/store/ducks/vendor/types';
import { getVendorListFetch } from '../endpoints/vendor';

export const getVendorListFetchResolver: (
  params: IGetVendorListReqModel,
) => Promise<AxiosResponse<IVendorState>> = (
  params: IGetVendorListReqModel,
) => {
  return getVendorListFetch(params)
    .then((res: AxiosResponse<IVendorState>) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
