import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import RouteBase from 'src/routes/RouteBase';
import withSuspense from 'src/components/hoc/withSuspense';
import HelmetTittle from '../Helmet';

export type RouteRendererPropTypes = {
  route: RouteBase;
  exact?: boolean;
};

const RouteRenderer: FC<RouteRendererPropTypes> = ({
  route,
  exact = false,
  ...rest
}: RouteRendererPropTypes): JSX.Element => {
  const RouteWithSuspense = withSuspense(route.component);

  return (
    <>
      <HelmetTittle route={route} />
      <Route
        exact={exact}
        path={route.fullPath}
        component={RouteWithSuspense}
        {...rest}
      />
    </>
  );
};

export default RouteRenderer;
