const parseParams = (paramArray: string): any => {
  if (typeof paramArray !== 'string') {
    throw new Error('`parseParams(string)` expects a string argument.');
  }
  let array = paramArray.substring(paramArray.indexOf('?') + 1).split('&');
  if (array[0] !== '') {
    let params = {};
    let x;
    for (let i = 0; i < array.length; i++) {
      x = array[i].toString().split('=');
      if (params[x[0]]) {
        if (typeof params[x[0]] == 'string') {
          params[x[0]] = [params[x[0]], x[1]];
        } else {
          params[x[0]] = [...params[x[0]], x[1]];
        }
      } else {
        params[x[0]] = x[1];
      }
    }
    return params;
  } else return null;
};

export default parseParams;
