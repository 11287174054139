import RouteBase from './RouteBase';
import Home from 'src/pages/Home';
import NotFoundPage from 'src/pages/NotFound';
import RolList from 'src/pages/Rol/List';
import KullaniciList from 'src/pages/Kullanici/List';
import KullaniciDetail from 'src/pages/Kullanici/Detail';
import RoleDetail from 'src/pages/Rol/Detail';
import HizmetList from 'src/pages/Hizmet/List';
import ServiceDetail from 'src/pages/Hizmet/Detail';

export const HomeRoute = new RouteBase({
  name: '',
  component: Home,
  path: '/',
});
export const NotFound = new RouteBase({
  name: 'Sayfa Bulunamadı',
  component: NotFoundPage,
  path: 'Not-found',
});
export const RolRoute = new RouteBase({
  name: 'Roller',
  component: RolList,
  path: 'Roller',
});
export const RolCreateRoute = new RouteBase({
  name: 'Rol Detay',
  component: RoleDetail,
  path: 'Rol',
  isPrivate: true,
});
export const RolDetailRoute = new RouteBase({
  name: 'Rol Ekle',
  component: RoleDetail,
  path: 'Rol/:userid',
});
export const UserRoute = new RouteBase({
  name: 'Kullanıcılar',
  component: KullaniciList,
  path: 'Kullanicilar',
});
export const UserCreateRoute = new RouteBase({
  name: 'Kullanıcı Detay',
  component: KullaniciDetail,
  path: 'Kullanici/:userid',
  isPrivate: true,
});
export const UserDetailRoute = new RouteBase({
  name: 'Kullanıcı Ekle',
  component: KullaniciDetail,
  path: 'Kullanici',
});
export const ServiceList = new RouteBase({
  name: 'Hizmetler',
  component: HizmetList,
  path: 'Hizmetler',
});
export const ServiceCreateRoute = new RouteBase({
  name: 'Hizmet Detay',
  component: ServiceDetail,
  path: 'Hizmet/:serviceid',
  isPrivate: true,
});
export const ServiceDetailRoute = new RouteBase({
  name: 'Hizmet Ekle',
  component: ServiceDetail,
  path: 'Hizmet',
});
