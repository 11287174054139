import createApiEndpoint from 'src/httpClient/endpoints';
import { REQUEST_METHODS } from 'src/httpClient/constants';
import {
  GET_SERVICE_COLLECTING_TYPE,
  GET_SERVICE_DELIVERY_TIME,
  GET_SERVICE_DETAIL,
  GET_SERVICE_LIST,
  GET_SERVICE_TRANSFER_TYPE,
  SET_SERVICE,
} from './constants';
import {
  IGetServiceListReqModel,
  IServiceDetailReqModel,
  IServiceRawModel,
} from 'src/store/ducks/service/types';
import {
  requestInterceptor,
  responseInterceptor,
} from 'src/httpClient/interceptors';
import { createHttpClient } from 'src/httpClient';
import { AxiosInstance } from 'axios';

const vendorHttpClient: AxiosInstance = createHttpClient(
  {
    baseURL: process.env.REACT_APP__VENDOR_DOMAIN?.concat(
      process.env.REACT_APP__BASE_URL_VERSION || '',
    ),
  },
  requestInterceptor,
  responseInterceptor,
);

export const getServiceListFetch = (params: IGetServiceListReqModel) => {
  return createApiEndpoint(
    GET_SERVICE_LIST,
    REQUEST_METHODS.GET,
    null,
    params,
    {},
    vendorHttpClient,
  );
};
export const getServiceDetailFetch = (params: IServiceDetailReqModel) => {
  return createApiEndpoint(
    `${GET_SERVICE_DETAIL}/${params.id}`,
    REQUEST_METHODS.GET,
    null,
    null,
    {},
    vendorHttpClient,
  );
};
export const getServiceDeliveryTimeFetch = () => {
  return createApiEndpoint(
    GET_SERVICE_DELIVERY_TIME,
    REQUEST_METHODS.GET,
    null,
    null,
    {},
    vendorHttpClient,
  );
};
export const getServiceCollectingTypeFetch = () => {
  return createApiEndpoint(
    GET_SERVICE_COLLECTING_TYPE,
    REQUEST_METHODS.GET,
    null,
    null,
    {},
    vendorHttpClient,
  );
};
export const getServiceTransferTypeFetch = () => {
  return createApiEndpoint(
    GET_SERVICE_TRANSFER_TYPE,
    REQUEST_METHODS.GET,
    null,
    null,
    {},
    vendorHttpClient,
  );
};
export const updateServiceFetch = (params: IServiceRawModel, payload: any) => {
  return createApiEndpoint(
    `${SET_SERVICE}/${payload.id}`,
    REQUEST_METHODS.PUT,
    params,
    null,
    {},
    vendorHttpClient,
  );
};
export const createServiceFetch = (params: IServiceRawModel) => {
  return createApiEndpoint(
    SET_SERVICE,
    REQUEST_METHODS.POST,
    params,
    null,
    {},
    vendorHttpClient,
  );
};
export const deleteServiceFetch = (params: IServiceDetailReqModel) => {
  return createApiEndpoint(
    `${SET_SERVICE}/${params.id}`,
    REQUEST_METHODS.DELETE,
    null,
    null,
    {},
    vendorHttpClient,
  );
};
