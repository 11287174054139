import { AxiosRequestConfig, AxiosError } from 'axios';
import { IRequestInterceptorProps } from 'src/httpClient/interceptors/types';
import arrangeCallNamesForLoader from 'src/common/helpers/arrangeCallNamesForLoader';
import bannedList from './bannedRequestForLoader';

const requestInterceptor: IRequestInterceptorProps = {
  onFulfilled: (configs: AxiosRequestConfig) => {
    const token: string | null = localStorage.getItem('token') || null;
    const newConfigs: AxiosRequestConfig = {
      ...configs,
      headers: {
        ...configs.headers,
        ...(token ? { Authorization: `Bearer ${token}` } : null),
      },
    };
    if (newConfigs.url) {
      let findIndex = bannedList.findIndex(item => item === newConfigs.url);
      if (findIndex === -1) {
        arrangeCallNamesForLoader(newConfigs.url);
      }
    }
    return newConfigs;
  },
  onRejected: (error: AxiosError) => {
    return Promise.reject(error);
  },
};

export default requestInterceptor;
